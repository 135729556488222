import { Document } from "../domain/Document";
import { DocumentId } from "../domain/DocumentId";
import { DocumentRepository } from "../domain/DocumentRepository";

export async function getDocumentByDocumentUidAndType(
  documentRepository: DocumentRepository,
  documentUid: string,
  documentType: string,
): Promise<Document> {
  console.log("documentUid:" + documentUid);
  console.log("documentType:" + documentType);
  const documentId = new DocumentId(documentUid);
  const document = await documentRepository.getDocumentByDocumentUidAndType(documentId.toString(), documentType);
  if (document === undefined) {
    throw new Error("Document not found");
  } else {
    return document;
  }
}
