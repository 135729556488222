import { Page } from "../../../context/Shared/domain/page/Page";
import { Movement } from "../domain/Movement";
import { MovementRepository } from "../domain/MovementRepository";
import { Criteria } from "../../../context/Shared/domain/criteria/Criteria";
import { MovementAccount } from "~/context/Shared/domain/MovementAccount";
import { Document } from "~/context/Documents/domain/Document";

export function listMovements(
  movementRepository: MovementRepository
): Promise<Movement[]> {
  return movementRepository.getMovements();
}

export function listMovementsByCriteria(
  movementRepository: MovementRepository,
  criteria: Criteria
): Promise<Page<Movement>> {
  return movementRepository.getMovementsByCriteria(criteria);
}

export function listMovementsByAccountUid(
  movementRepository: MovementRepository,
  accountUid: string
): Promise<Movement[] | undefined> {
  console.log("call listMovementsByAccountUid.getMovementsByAccountUid:" + accountUid);
  return movementRepository.getMovementsByAccountUid(accountUid);
}

export function listMovementsByAccountUidPaginate(
  movementRepository: MovementRepository,
  accountUid: string,
  page: number
): Promise<Movement[] | undefined> {
  return movementRepository.getMovementsByAccountUidPaginate(accountUid, page);
}

export function listMovementsByMovementUid(
  movementRepository: MovementRepository,
  movementUid: string
): Promise<Movement | undefined> {
  console.log(
    "trae movimiento: " + movementUid + " desde listMovementsByMovementUid"
  );
  return movementRepository.getMovementsByMovementUid(movementUid);
}

export function listMovementsByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  return movementRepository.getMovementsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listMovementsFullByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  return movementRepository.getMovementsFullByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listMovementsFullRepositoryByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  const ret = movementRepository.getMovementsFullRepositoryByCompanyAndCriteria(
    company_uid,
    criteria
  );
  console.log("listMovementsFullRepositoryByCompanyAndCriteria: ", JSON.stringify(ret));
  return ret;
}

export function listMovementsFullRepositoryByCompanyAndSearch(
  movementRepository: MovementRepository,
  company_uid: string,
  search: string
): Promise<Page<Movement>> {
  const ret = movementRepository.getMovementsFullRepositoryByCompanyAndSearch(
    company_uid,
    search
  );
  console.log("listMovementsRepositoryByCompanyAndSearch: ", JSON.stringify(ret));
  return ret;
}




export function listMovementsWithReconciliationsByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  return movementRepository.getMovementsWithReconciliationsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listMovementsFullWithReconciliationsByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  return movementRepository.getMovementsFullWithReconciliationsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}



export function listMovementsByCompanyDistanceAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  document_uid: string,
  type: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  console.log("dentro de listMovementsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " document_uid: ", document_uid, " type: ", type);
  return movementRepository.getMovementsByCompanyDistanceAndCriteria(
    company_uid,
    document_uid,
    type,
    criteria
  );
}





export async function listMovementsFullByCompanyDistanceAndCriteriaWithDocument(
  movementRepository: MovementRepository,
  company_uid: string,
  document: Document,
  criteria: Criteria
): Promise<Page<Movement>> {
  try {

    const document_uid = document.uid.toString();
    const type = document.document_type;

    console.log("dentro de listMovementsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " document_uid: ", document_uid, " type: ", type);

    const ret = await movementRepository.getMovementsFullByCompanyDistanceAndCriteria(company_uid, document_uid, type, criteria);
    // console.log("retorno listMovementsByCompanyDistanceAndCriteria: " + JSON.stringify(ret));
    const movements = orderByDifference(ret, document);
    // console.log("retorno orderByDifference: " + JSON.stringify(movements));
    return new Page<Movement>(movements.length, movements);



  } catch (error) {
    console.log("error en listDocumentsByCompanyDistanceAndCriteriaWithMovement: ", error);
    return new Page<Movement>(0, []);

  }

}


export async function listMovementsFullByCompanyDistanceAndCriteriaWithMovement(
  movementRepository: MovementRepository,
  company_uid: string,
  movement: Movement,
  criteria: Criteria
): Promise<Page<Movement>> {
  try {

    const movement_uid = movement.uid.toString();
    const type = movement.movement_type;

    console.log("dentro de listMovementsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " document_uid: ", movement_uid, " type: ", type);

    const ret = await movementRepository.getMovementsFullByCompanyDistanceAndCriteria(company_uid, movement_uid, type, criteria);
    console.log("retorno listMovementsByCompanyDistanceAndCriteria: " + JSON.stringify(ret));
    const movements = orderMovementsByDifference(ret, movement);
    console.log("retorno orderByDifference: " + JSON.stringify(movements));
    return new Page<Movement>(movements.length, movements);



  } catch (error) {
    console.log("error en listDocumentsByCompanyDistanceAndCriteriaWithMovement: ", error);
    return new Page<Movement>(0, []);

  }

}





export async function listMovementsByCompanyDistanceAndCriteriaWithDocument(
  movementRepository: MovementRepository,
  company_uid: string,
  document: Document,
  criteria: Criteria
): Promise<Page<Movement>> {
  try {

    const document_uid = document.uid.toString();
    const type = document.document_type;

    console.log("dentro de listMovementsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " document_uid: ", document_uid, " type: ", type);

    const ret = await movementRepository.getMovementsByCompanyDistanceAndCriteria(company_uid, document_uid, type, criteria);
    console.log("retorno listMovementsByCompanyDistanceAndCriteria: " + JSON.stringify(ret));
    const movements = orderByDifference(ret, document);
    console.log("retorno orderByDifference: " + JSON.stringify(movements));
    return new Page<Movement>(movements.length, movements);



  } catch (error) {
    console.log("error en listDocumentsByCompanyDistanceAndCriteriaWithMovement: ", error);
    return new Page<Movement>(0, []);

  }

}

export function listMovementsWithCompanyByCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<MovementAccount>> {
  return movementRepository.getMovementsWithCompanyByCompanyAndCriteria(
    company_uid,
    criteria
  );
}



export function listMovementsByAccountCompanyAndCriteria(
  movementRepository: MovementRepository,
  company_uid: string,
  account_uid: string,
  criteria: Criteria
): Promise<Page<Movement>> {
  console.log("listMovement.listMovementsByAccountCompanyAndCriteria: account_uid: " + account_uid + "company_uid: " + company_uid + " criteria: " + JSON.stringify(criteria));
  return movementRepository.getMovementsByCompanyAccountAndCriteria(
    company_uid,
    account_uid,
    criteria
  );
}


function orderByDifference(response: Page<Movement>, document: Document): Movement[] {
  //console.log("dentro de orderByDifference Movement" + response.content.length);
  const pageMovement = response.content.map((mov) => {
    //console.log("mov: ", JSON.stringify(mov));
    // console.log("document: ", document.document_total_amount);
    // console.log("mov.movement_total_amount: ", mov.movement_total_amount);
    const documentBalance = document.document_balance || 0;
    const movementBalance = mov.movement_balance || 0;
    // console.log("documentBalance: ", documentBalance);
    // console.log("movementBalance: ", movementBalance);

    //console.log("difference: ", Math.abs(Math.abs(documentBalance) - Math.abs(movementBalance)));
    return ({
      ...mov,
      difference: Math.abs(Math.abs(documentBalance) - Math.abs(movementBalance))
    })

  }).sort((a, b) => a.difference - b.difference)
    .map((mov) => mov);
  return pageMovement;
}

function orderMovementsByDifference(response: Page<Movement>, movement: Movement): Movement[] {
  // console.log("dentro de orderByDifference Movement" + response.content.length);
  const pageMovement = response.content.map((mov) => {
    // console.log("mov: ", JSON.stringify(mov));
    // console.log("movement: ", movement.movement_total_amount);
    // console.log("mov.movement_total_amount: ", mov.movement_total_amount);
    const documentBalance = movement.movement_balance || 0;
    const movementBalance = mov.movement_balance || 0;
    // console.log("documentBalance: ", documentBalance);
    //  console.log("movementBalance: ", movementBalance);

    // console.log("difference: ", Math.abs(Math.abs(documentBalance) - Math.abs(movementBalance)));
    return ({
      ...mov,
      difference: Math.abs(Math.abs(documentBalance) - Math.abs(movementBalance))
    })

  }).sort((a, b) => a.difference - b.difference)
    .map((mov) => mov);
  return pageMovement;
}



