import { Filter } from "./Filter";
import { Order } from "./Order";

const DEFAULT_PAGE_SIZE = 10;

export class Criteria {
  readonly filters: Array<Filter>;
  readonly order?: Order;
  readonly pageSize: number;
  readonly pageNumber: number;

  constructor(
    pageNumber: number,
    pageSize: number = DEFAULT_PAGE_SIZE,
    filters: Array<Filter>,
    order?: Order
  ) {
    this.filters = filters;
    this.order = order;
    this.pageSize = pageSize;
    this.pageNumber = pageNumber;
  }

  public hasFilters(): boolean {
    return this.filters.length > 0;
  }
}
