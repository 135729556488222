<template></template>
<script>
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Filter } from "~/context/Shared/domain/criteria/Filter";
import { Order } from "~/context/Shared/domain/criteria/Order";
import { DocumentDomain } from "~/context/Shared/domain/DocumentDomain";

export function returnBalanceToMovementFromReconciliation(movement, reconciliation) {
  let reconciliation_amount_assign =
    reconciliation.reconciliation_reference_amount -
    reconciliation.reconciliation_reference_balance;
  movement.movement_balance = movement.movement_balance + reconciliation_amount_assign;
  return movement;
}

export function transformReferenceToObject(recReference, recRefTemporalBalance) {
  // segundo transformar el objeto de reconciliacion a un objeto de invoice o al que corresponda.

  console.log("en transformReferenceToObject ");
  console.log("recReference: " + JSON.stringify(recReference));
  console.log("DocumentDomain.MOVEMENT: " + DocumentDomain.MOVEMENT);
  console.log(
    "recReference.reconciliation_reference_type: " +
      recReference.reconciliation_reference_type
  );
  console.log(
    "recReference: " +
      (recReference.reconciliation_reference_type === DocumentDomain.MOVEMENT)
  );

  let referenceAsObject = {};
  if (recReference.reconciliation_reference_type === DocumentDomain.INVOICE) {
    console.log("es un " + DocumentDomain.INVOICE);
    referenceAsObject = {
      uid: recReference.reconciliation_reference_object_uid,
      company_uid: recReference.reconciliation_reference_company_uid,
      invoice_number: recReference.reconciliation_reference_businessNumber,
      invoice_receiver_legal_id: recReference.reconciliation_reference_receiver_legal_id,
      invoice_sender_legal_id: recReference.reconciliation_reference_sender_legal_id,
      invoice_total_amount: recReference.reconciliation_reference_total_amount,
      invoice_net_amount: recReference.reconciliation_reference_net_amount,
      invoice_exempt_amount: recReference.reconciliation_reference_exempt_amount,
      invoice_tax_amount: recReference.reconciliation_reference_tax_amount,
      invoice_type: recReference.reconciliation_reference_type,
      invoice_balance: recReference.reconciliation_reference_balance,
      invoice_balance_currency: recReference.reconciliation_reference_balance_currency,
      invoice_number: recReference.reconciliation_reference_businessNumber,
      invoice_date: recReference.reconciliation_reference_date,
      invoice_reception_date: recReference.reconciliation_reference_reception_date,
      invoice_sender_name: recReference.reconciliation_reference_description,
      invoice_currency: recReference.reconciliation_reference_currency,
      invoice_original_type: recReference.reconciliation_reference_original_type,
      invoice_state: recReference.reconciliation_reference_state,
    };
  } else if (recReference.reconciliation_reference_type === DocumentDomain.MOVEMENT) {
    console.log("es un XX: " + DocumentDomain.MOVEMENT);
    referenceAsObject = {
      uid: recReference.reconciliation_reference_object_uid,
      account_uid: recReference.reconciliation_reference_account_uid,
      company_uid: recReference.reconciliation_reference_company_uid,
      movement_account_number: recReference.reconciliation_reference_account_number,
      movement_reference: recReference.reconciliation_reference_businessNumber,
      //TODO: revisar esto del balance
      movement_balance: recRefTemporalBalance,
      movement_balance_currency: recReference.reconciliation_reference_balance_currency,
      movement_total_amount: recReference.reconciliation_reference_total_amount,
      movement_date: recReference.reconciliation_reference_date,
      movement_transaction_date: recReference.reconciliation_reference_transaction_date,
      movement_description: recReference.reconciliation_reference_description,
      movement_currency: recReference.reconciliation_reference_currency,
      movement_type: DocumentDomain.MOVEMENT,
      movement_origin_id: recReference.reconciliation_reference_origin_id,
      movement_original_type: recReference.reconciliation_reference_original_type,
      movement_pending: recReference.reconciliation_reference_pending,
      movement_client_provider: recReference.reconciliation_reference_client_provider,
      movement_sender: recReference.reconciliation_reference_sender,
      movement_sender_legal_id: recReference.reconciliation_reference_sender_legal_id,
      movement_type_format: formatMovementType(
        DocumentDomain.MOVEMENT,
        recReference.reconciliation_reference_original_type,
        recReference.reconciliation_reference_total_amount
      ),
      //TODO: agregar la difference
    };
  } else {
    console.log("no es nada " + recReference.reconciliation_reference_type);
  }

  return referenceAsObject;
}

export function transformReconciliationToInvoice(reconciliation, assignAmount) {
  let reconAsInvoice = {};
  reconAsInvoice = {
    uid: reconciliation.reconciliation_reference_object_uid,
    invoice_number: reconciliation.reconciliation_reference_businessNumber,
    invoice_balance: reconciliation.reconciliation_reference_balance + assignAmount,
    invoice_total_amount: reconciliation.reconciliation_reference_amount,
    invoice_date: reconciliation.reconciliation_reference_date,
    invoice_sender_name: reconciliation.reconciliation_reference_description,
    invoice_currency: reconciliation.reconciliation_reference_currency,
    invoice_type: reconciliation.reconciliation_reference_type,
  };

  return reconAsInvoice;
}

export async function loadPageInvoiceWithDistance(
  companyId,
  movementId,
  pageNumber,
  pageSize
) {
  const defaultFilter = new Filter("company_uid", "=", companyId);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);

  const criteria = new Criteria(pageNumber, pageSize, arrayFilters);

  const pageInvoice = await useInvoiceCompanyDistanceCriteria(
    companyId,
    movementId,
    criteria
  );
  return pageInvoice;
}

export async function loadPageDocumentWithDistance(
  companyId,
  movement,
  pageNumber,
  pageSize
) {
  console.log(
    "loadPageDocumentWithDistance: " +
      companyId +
      " / " +
      movement.uid +
      " / " +
      pageNumber +
      " / " +
      pageSize
  );
  const defaultFilter = new Filter("company_uid", "=", companyId);

  const balanceFilter = new Filter("document_balance", ">", 0);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);
  arrayFilters.push(balanceFilter);

  const criteria = new Criteria(pageNumber, pageSize, arrayFilters);

  const pageDocument = await useDocumentCompanyDistanceCriteriaWithMovement(
    companyId,
    movement,
    criteria
  );
  return pageDocument;
}

export async function loadPageMovementWithDistance(
  companyId,
  document,
  pageNumber,
  pageSize
) {
  console.log("llamando loadPageMovementWithDistance:" + pageNumber + " / " + pageSize);
  const defaultFilter = new Filter("company_uid", "=", companyId);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);

  const criteria = new Criteria(pageNumber, pageSize, arrayFilters);

  //console.log("loadPageMovementWithDistance (document): " + JSON.stringify(document));
  if (document && document.movement_type === DocumentDomain.MOVEMENT) {
    const pageMovement = await useMovementCompanyDistanceCriteriaWithMovement(
      companyId,
      document,
      criteria
    );
    return pageMovement;
  } else if (
    document &&
    (document.document_type === DocumentDomain.INVOICE ||
      document.document_type === DocumentDomain.BILL ||
      document.document_type === DocumentDomain.EXPENSE)
  ) {
    const pageMovement = await useMovementCompanyDistanceCriteriaWithDocument(
      companyId,
      document,
      criteria
    );
    return pageMovement;
  }
}

export async function loadPageActiveMovementWithDistance(
  companyId,
  documentId,
  type,
  pageNumber,
  pageSize
) {
  const defaultFilter = new Filter("company_uid", "=", companyId);

  const active = new Filter("movement_balance", "!=", 0);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);
  arrayFilters.push(active);

  const criteria = new Criteria(pageNumber, pageSize, arrayFilters);
  console.log("loadPageActiveMovementWithDistance: " + JSON.stringify(criteria));
  const pageMovement = await useMovementCompanyDistanceCriteria(
    companyId,
    documentId,
    type,
    criteria
  );
  console.log("pageMovement: " + JSON.stringify(pageMovement));
  return pageMovement;
}

export async function loadPageActiveOutcomeMovementWithDistance(
  companyId,
  documentId,
  type,
  pageNumber,
  pageSize
) {
  const defaultFilter = new Filter("company_uid", "=", companyId);
  const active = new Filter("movement_balance", "!=", 0);
  const outcome = new Filter("movement_amount", "<", 0);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);
  arrayFilters.push(active);
  arrayFilters.push(outcome);

  const criteria = new Criteria(pageNumber, pageSize, arrayFilters);

  console.log("loadPageActiveOutcomeMovementWithDistance: " + JSON.stringify(criteria));
  const pageMovement = await useMovementCompanyDistanceCriteria(
    companyId,
    documentId,
    type,
    criteria
  );
  console.log("pageMovement: " + JSON.stringify(pageMovement));
  return pageMovement;
}

export function addUniqueObjects(objectsNew, objectsOld) {
  const uids = new Set(objectsOld.map((object) => object.uid));

  for (let i = 0, len = objectsNew.length; i < len; i++) {
    if (!uids.has(objectsNew[i].uid)) {
      objectsOld.push(objectsNew[i]);
    } else {
      console.log("ya existe" + objectsNew[i].uid);
    }
  }
  return objectsOld;
}

export function sortObjects(field, order, objectsDB, pageNumber, pageSize) {
  let ascending = order === "asc";
  if (order === "desc") {
    ascending = false;
  }
  console.log(
    "field: " +
      field +
      " order: " +
      JSON.stringify(order) +
      " objectsDB: " +
      JSON.stringify(objectsDB.length) +
      " pageNumber: " +
      pageNumber +
      " pageSize: " +
      pageSize
  );
  //console.log(" objectsDB unorder -->: " + JSON.stringify(objectsDB));
  if (objectsDB?.length > 0) {
    objectsDB.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (field.toString().includes("date")) {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      } else if (field.toString().endsWith("_amount")) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
      } else if (field.toString().endsWith("_balance")) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
      }

      if (typeof valueA === "number") {
        //console.log("number");
        return ascending ? valueA - valueB : valueB - valueA;
      }
      if (valueA instanceof Date) {
        //console.log("valueA: " + valueA.getTime() + " valueB: " + valueB.getTime());
        return ascending
          ? valueA.getTime() - valueB.getTime()
          : valueB.getTime() - valueA.getTime();
      }

      if (typeof valueA === "string") {
        //    console.log("string");
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return 0;
    });

    const startIdx = (pageNumber - 1) * pageSize;
    const endIdx = startIdx + pageSize;
    //console.log(" objectsDB order: " + JSON.stringify(objectsDB))
    return objectsDB?.slice(startIdx, endIdx);
  } else {
    return objectsDB;
  }
}

export function sortObjectsFull(field, order, objectsDB) {
  let ascending = order === "asc";
  if (order === "desc") {
    ascending = false;
  }
  console.log(
    "field: " +
      field +
      " order: " +
      JSON.stringify(order) +
      " objectsDB: " +
      JSON.stringify(objectsDB.length)
  );
  //console.log(" objectsDB unorder -->: " + JSON.stringify(objectsDB));
  if (objectsDB?.length > 0) {
    objectsDB.sort((a, b) => {
      let valueA = a[field];
      let valueB = b[field];

      if (field.toString().includes("date")) {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      } else if (field.toString().endsWith("_amount")) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
      } else if (field.toString().endsWith("_balance")) {
        valueA = parseFloat(valueA);
        valueB = parseFloat(valueB);
      }

      if (typeof valueA === "number") {
        //console.log("number");
        return ascending ? valueA - valueB : valueB - valueA;
      }
      if (valueA instanceof Date) {
        //console.log("valueA: " + valueA.getTime() + " valueB: " + valueB.getTime());
        return ascending
          ? valueA.getTime() - valueB.getTime()
          : valueB.getTime() - valueA.getTime();
      }

      if (typeof valueA === "string") {
        //    console.log("string");
        return ascending ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
      }

      return 0;
    });

    //    const startIdx = (pageNumber - 1) * pageSize;
    //   const endIdx = startIdx + pageSize;
    //console.log(" objectsDB order: " + JSON.stringify(objectsDB))
  }
  return objectsDB;
}

export function getReconciliationAndReference(
  reconciliation,
  reconciliationReference,
  fromObject,
  toObject
) {
  console.log("reconciliation: " + JSON.stringify(reconciliation));
  console.log("reference: " + JSON.stringify(reconciliationReference));
  console.log("fromObject: " + fromObject);
  console.log("toObject: " + toObject);
  console.log("--->" + (toObject && toObject == DocumentDomain.EXPENSE));
  let recTemporal = {};
  let recrefTemporal = {};
  if (fromObject && fromObject == DocumentDomain.MOVEMENT) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_account_uid: reconciliation.account_uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_account_number: reconciliation.movement_account_number,
      reconciliation_total_amount: parseFloat(reconciliation.movement_total_amount),
      reconciliation_type: reconciliation.movement_type,
      reconciliation_balance: reconciliation.movement_balance
        ? parseFloat(reconciliation.movement_balance)
        : parseFloat(reconciliation.movement_total_amount),
      reconciliation_balance_currency: reconciliation.movement_balance_currency,
      reconciliation_businessNumber: reconciliation.movement_reference,
      reconciliation_date: reconciliation.movement_date,
      reconciliation_transaction_date: reconciliation.movement_transaction_date,
      reconciliation_description: reconciliation.movement_description,
      reconciliation_currency: reconciliation.movement_currency,
      reconciliation_origin_id: reconciliation.movement_origin_id,
      reconciliation_original_type: reconciliation.movement_original_type,
      reconciliation_pending: reconciliation.movement_pending,
      reconciliation_client_provider: reconciliation.movement_client_provider,
    };
  }
  //TODO: cambiar document_sender po document_aditional_info
  else if (fromObject && fromObject == DocumentDomain.DOCUMENT) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.document_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.document_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.document_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.document_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.document_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.document_tax_amount),
      reconciliation_type: reconciliation.document_type,
      reconciliation_balance: reconciliation.document_balance
        ? parseFloat(reconciliation.document_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.document_balance_currency,
      reconciliation_businessNumber: reconciliation.document_number,
      reconciliation_date: reconciliation.document_date,
      reconciliation_reception_date: reconciliation.document_reception_date,
      reconciliation_description: reconciliation.document_sender_name,
      reconciliation_currency: reconciliation.document_currency,
      reconcilation_state: reconciliation.document_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.INVOICE) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.invoice_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.invoice_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.invoice_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.invoice_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.invoice_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.invoice_tax_amount),
      reconciliation_type: reconciliation.invoice_type,
      reconciliation_balance: reconciliation.invoice_balance
        ? parseFloat(reconciliation.invoice_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.invoice_balance_currency,
      reconciliation_businessNumber: reconciliation.invoice_number,
      reconciliation_date: reconciliation.invoice_date,
      reconciliation_reception_date: reconciliation.invoice_reception_date,
      reconciliation_description: reconciliation.invoice_sender_name,
      reconciliation_currency: reconciliation.invoice_currency,
      reconcilation_state: reconciliation.invoice_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.BILL) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.bill_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.bill_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.bill_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.bill_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.bill_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.bill_tax_amount),
      reconciliation_type: reconciliation.bill_type,
      reconciliation_balance: reconciliation.bill_balance
        ? parseFloat(reconciliation.bill_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.bill_balance_currency,
      reconciliation_businessNumber: reconciliation.bill_number,
      reconciliation_date: reconciliation.bill_date,
      reconciliation_reception_date: reconciliation.bill_reception_date,
      reconciliation_description: reconciliation.bill_sender_name,
      reconciliation_currency: reconciliation.bill_currency,
      reconcilation_state: reconciliation.bill_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.EXPENSE) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.expense_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.expense_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.expense_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.expense_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.expense_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.expense_tax_amount),
      reconciliation_type: reconciliation.expense_type,
      reconciliation_balance: reconciliation.expense_balance
        ? parseFloat(reconciliation.expense_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.expense_balance_currency,
      reconciliation_businessNumber: reconciliation.expense_number,
      reconciliation_date: reconciliation.expense_date,
      reconciliation_reception_date: reconciliation.expense_reception_date,
      reconciliation_description: reconciliation.expense_sender_name,
      reconciliation_currency: reconciliation.expense_currency,
      reconcilation_state: reconciliation.expense_state,
    };
  } else {
    recTemporal = {
      reconciliation_object_uid: "NO_UID",
    };
  }

  if (toObject && toObject == DocumentDomain.MOVEMENT) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_account_uid: reconciliationReference.account_uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_account_number:
        reconciliationReference.movement_account_number,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.movement_total_amount
      ),
      reconciliation_reference_type: reconciliationReference.movement_type,
      reconciliation_reference_balance: parseFloat(
        reconciliationReference.movement_balance
      ),
      reconciliation_reference_balance_currency:
        reconciliationReference.movement_balance_currency,
      reconciliation_reference_businessNumber: reconciliationReference.movement_reference,
      reconciliation_reference_date: reconciliationReference.movement_date,
      reconciliation_reference_transaction_date:
        reconciliationReference.movement_transaction_date,
      reconciliation_reference_description: reconciliationReference.movement_description,
      reconciliation_reference_currency: reconciliationReference.movement_currency,
      reconciliation_reference_origin_id: reconciliationReference.movement_origin_id,
      reconciliation_reference_original_type:
        reconciliationReference.movement_original_type,
      reconciliation_reference_pending: reconciliationReference.movement_pending,
      reconciliation_reference_client_provider:
        reconciliationReference.movement_client_provider,
      reconciliation_reference_sender: reconciliationReference.movement_sender,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.movement_sender_legal_id,
    };
    console.log("rec ref is MOVEMENTX: " + JSON.stringify(recrefTemporal));
  }
  //TODO: cambiar document_sender po document_aditional_info
  else if (toObject && toObject == DocumentDomain.DOCUMENT) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_receiver_legal_id:
        reconciliationReference.document_receiver_legal_id,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.document_sender_legal_id,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.document_total_amount
      ),
      reconciliation_reference_net_amount: parseFloat(
        reconciliationReference.document_net_amount
      ),
      reconciliation_reference_exempt_amount: parseFloat(
        reconciliationReference.document_exempt_amount
      ),
      reconciliation_reference_tax_amount: parseFloat(
        reconciliationReference.document_tax_amount
      ),
      reconciliation_reference_type: reconciliationReference.document_type,
      reconciliation_reference_balance: parseFloat(
        reconciliationReference.document_balance
      ),
      reconciliation_reference_balance_currency:
        reconciliationReference.document_balance_currency,
      reconciliation_reference_businessNumber: reconciliationReference.document_number,
      reconciliation_reference_date: reconciliationReference.document_date,
      reconciliation_reference_reception_date:
        reconciliationReference.document_reception_date,
      reconciliation_reference_description:
        reconciliationReference.document_enterprise_name,
      reconciliation_reference_currency: reconciliationReference.document_currency,
      reconciliation_reference_state: reconciliationReference.document_state,
      reconciliation_reference_isDocument: true,
    };
  } else if (toObject && toObject == DocumentDomain.INVOICE) {
    if (reconciliationReference.document_isDocument) {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.document_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.document_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.document_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.document_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.document_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.document_total_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.document_type,
        reconciliation_reference_original_type:
          reconciliationReference.document_original_type,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.document_balance
        ),
        reconciliation_reference_balance_currency:
          reconciliationReference.document_balance_currency,
        reconciliation_reference_businessNumber: reconciliationReference.document_number,
        reconciliation_reference_date: reconciliationReference.document_date,
        reconciliation_reference_reception_date:
          reconciliationReference.document_reception_date,
        reconciliation_reference_description:
          reconciliationReference.document_enterprise_name,
        reconciliation_reference_currency: reconciliationReference.document_currency,
        reconciliation_reference_state: reconciliationReference.document_state,
        reconciliation_reference_enterprise_name:
          reconciliationReference.document_enterprise_name,
        reconciliation_reference_isDocument: true,
      };
      console.log("rec ref is document: " + JSON.stringify(recrefTemporal));
    } else {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.invoice_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.invoice_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.invoice_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.invoice_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.invoice_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.invoice_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.invoice_type,
        reconciliation_reference_original_type:
          reconciliationReference.invoice_original_type,
        reconciliation_reference_balance_currency:
          reconciliationReference.invoice_balance_currency,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.invoice_balance
        ),
        reconciliation_reference_businessNumber: reconciliationReference.invoice_number,
        reconciliation_reference_date: reconciliationReference.invoice_date,
        reconciliation_reference_reception_date:
          reconciliationReference.invoice_reception_date,
        reconciliation_reference_description: reconciliationReference.invoice_sender_name,
        reconciliation_reference_currency: reconciliationReference.invoice_currency,
        reconciliation_reference_state: reconciliationReference.invoice_state,
      };
    }
  } else if (toObject && toObject == DocumentDomain.BILL) {
    if (reconciliationReference.document_isDocument) {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.document_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.document_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.document_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.document_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.document_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.document_total_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.document_type,
        reconciliation_reference_original_type:
          reconciliationReference.document_original_type,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.document_balance
        ),
        reconciliation_reference_balance_currency:
          reconciliationReference.document_balance_currency,
        reconciliation_reference_businessNumber: reconciliationReference.document_number,
        reconciliation_reference_date: reconciliationReference.document_date,
        reconciliation_reference_reception_date:
          reconciliationReference.document_reception_date,
        reconciliation_reference_description:
          reconciliationReference.document_enterprise_name,
        reconciliation_reference_currency: reconciliationReference.document_currency,
        reconciliation_reference_state: reconciliationReference.document_state,
        reconciliation_reference_enterprise_name:
          reconciliationReference.document_enterprise_name,
        reconciliation_reference_isDocument: true,
      };
    } else {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.bill_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.bill_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.bill_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.bill_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.bill_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.bill_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.bill_type,
        reconciliation_reference_original_type:
          reconciliationReference.bill_original_type,
        reconciliation_reference_balance_currency:
          reconciliationReference.bill_balance_currency,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.bill_balance
        ),
        reconciliation_reference_businessNumber: reconciliationReference.bill_number,
        reconciliation_reference_date: reconciliationReference.bill_date,
        reconciliation_reference_reception_date:
          reconciliationReference.bill_reception_date,
        reconciliation_reference_description: reconciliationReference.bill_sender_name,
        reconciliation_reference_currency: reconciliationReference.bill_currency,
        reconciliation_reference_state: reconciliationReference.bill_state,
      };
    }
  } else if (toObject && toObject == DocumentDomain.EXPENSE) {
    console.log(
      "reconciliationReference.document_isDocument:" +
        reconciliationReference.document_isDocument
    );
    if (reconciliationReference.document_isDocument) {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.document_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.document_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.document_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.document_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.document_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.document_total_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.document_type,
        reconciliation_reference_original_type:
          reconciliationReference.document_original_type,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.document_balance
        ),
        reconciliation_reference_balance_currency:
          reconciliationReference.document_balance_currency,
        reconciliation_reference_businessNumber: reconciliationReference.document_number,
        reconciliation_reference_date: reconciliationReference.document_date,
        reconciliation_reference_reception_date:
          reconciliationReference.document_reception_date,
        reconciliation_reference_description:
          reconciliationReference.document_enterprise_name,
        reconciliation_reference_currency: reconciliationReference.document_currency,
        reconciliation_reference_state: reconciliationReference.document_state,
        reconciliation_reference_isDocument: true,
      };
    } else {
      recrefTemporal = {
        reconciliation_reference_object_uid: reconciliationReference.uid,
        reconciliation_reference_company_uid: reconciliationReference.company_uid,
        reconciliation_reference_receiver_legal_id:
          reconciliationReference.expense_receiver_legal_id,
        reconciliation_reference_sender_legal_id:
          reconciliationReference.expense_sender_legal_id,
        reconciliation_reference_total_amount: parseFloat(
          reconciliationReference.expense_total_amount
        ),
        reconciliation_reference_net_amount: parseFloat(
          reconciliationReference.expense_net_amount
        ),
        reconciliation_reference_exempt_amount: parseFloat(
          reconciliationReference.expense_exempt_amount
        ),
        reconciliation_reference_tax_amount: parseFloat(
          reconciliationReference.expense_tax_amount
        ),
        reconciliation_reference_type: reconciliationReference.expense_type,
        reconciliation_reference_original_type:
          reconciliationReference.expense_original_type,
        reconciliation_reference_balance_currency:
          reconciliationReference.expense_balance_currency,
        reconciliation_reference_balance: parseFloat(
          reconciliationReference.expense_balance
        ),
        reconciliation_reference_businessNumber: reconciliationReference.expense_number,
        reconciliation_reference_date: reconciliationReference.expense_date,
        reconciliation_reference_reception_date:
          reconciliationReference.expense_reception_date,
        reconciliation_reference_description: reconciliationReference.expense_sender_name,
        reconciliation_reference_currency: reconciliationReference.expense_currency,
        reconciliation_reference_state: reconciliationReference.expense_state,
      };
    }
  } else {
    recrefTemporal = {
      reconciliation_reference_object_uid: "NO_UID_REF",
    };
  }

  /*
  if ((this.invoiceAsReconciliationAddedToMovement.filter((item) => item.reconciliation_reference_object_uid == reconciliationReference.uid)).length > 0) {
    console.log("ya existe");
    alert("El " + recrefTemporal.reconciliation_reference_type + " con valor " + recrefTemporal.reconciliation_reference_total_amount + " ya existe en la lista de conciliaciones seleccionadas.");
    return [-1, -1];
  }
*/
  return [recTemporal, recrefTemporal];
}

export function createInitialReconciliationAndReference(
  reconciliation,
  reconciliationReference,
  fromObject,
  toObject
) {
  console.log("reconciliation: " + JSON.stringify(reconciliation));
  console.log("reference: " + JSON.stringify(reconciliationReference));
  console.log("fromObject: " + fromObject);
  console.log("toObject: " + toObject);
  let recTemporal = {};
  let recrefTemporal = {};
  if (fromObject && fromObject == DocumentDomain.MOVEMENT) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_account_uid: reconciliation.account_uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_account_number: reconciliation.movement_account_number,
      reconciliation_total_amount: parseFloat(reconciliation.movement_total_amount),
      reconciliation_type: reconciliation.movement_type,
      reconciliation_balance: reconciliation.movement_balance
        ? parseFloat(reconciliation.movement_balance)
        : parseFloat(reconciliation.movement_total_amount),
      reconciliation_balance_currency: reconciliation.movement_balance_currency,
      reconciliation_businessNumber: reconciliation.movement_reference,
      reconciliation_date: reconciliation.movement_date,
      reconciliation_transaction_date: reconciliation.movement_transaction_date,
      reconciliation_description: reconciliation.movement_description,
      reconciliation_currency: reconciliation.movement_currency,
      reconciliation_origin_id: reconciliation.movement_origin_id,
      reconciliation_original_type: reconciliation.movement_original_type,
      reconciliation_pending: reconciliation.movement_pending,
    };
  }
  //TODO: cambiar document_sender po document_aditional_info
  else if (fromObject && fromObject == DocumentDomain.DOCUMENT) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.document_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.document_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.document_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.document_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.document_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.document_tax_amount),
      reconciliation_type: reconciliation.document_type,
      reconciliation_balance: reconciliation.document_balance
        ? parseFloat(reconciliation.document_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.document_balance_currency,
      reconciliation_businessNumber: reconciliation.document_number,
      reconciliation_date: reconciliation.document_date,
      reconciliation_reception_date: reconciliation.document_reception_date,
      reconciliation_description: reconciliation.document_sender_name,
      reconciliation_currency: reconciliation.document_currency,
      reconcilation_state: reconciliation.document_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.INVOICE) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.invoice_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.invoice_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.invoice_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.invoice_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.invoice_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.invoice_tax_amount),
      reconciliation_type: reconciliation.invoice_type,
      reconciliation_balance: reconciliation.invoice_balance
        ? parseFloat(reconciliation.invoice_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.invoice_balance_currency,
      reconciliation_businessNumber: reconciliation.invoice_number,
      reconciliation_date: reconciliation.invoice_date,
      reconciliation_reception_date: reconciliation.invoice_reception_date,
      reconciliation_description: reconciliation.invoice_sender_name,
      reconciliation_currency: reconciliation.invoice_currency,
      reconcilation_state: reconciliation.invoice_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.BILL) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.bill_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.bill_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.bill_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.bill_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.bill_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.bill_tax_amount),
      reconciliation_type: reconciliation.bill_type,
      reconciliation_balance: reconciliation.bill_balance
        ? parseFloat(reconciliation.bill_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.bill_balance_currency,
      reconciliation_businessNumber: reconciliation.bill_number,
      reconciliation_date: reconciliation.bill_date,
      reconciliation_reception_date: reconciliation.bill_reception_date,
      reconciliation_description: reconciliation.bill_sender_name,
      reconciliation_currency: reconciliation.bill_currency,
      reconcilation_state: reconciliation.bill_state,
    };
  } else if (fromObject && fromObject == DocumentDomain.EXPENSE) {
    recTemporal = {
      reconciliation_object_uid: reconciliation.uid,
      reconciliation_company_uid: reconciliation.company_uid,
      reconciliation_receiver_legal_id: reconciliation.expense_receiver_legal_id,
      reconciliation_sender_legal_id: reconciliation.expense_sender_legal_id,
      reconciliation_total_amount: parseFloat(reconciliation.expense_total_amount),
      reconciliation_net_amount: parseFloat(reconciliation.expense_net_amount),
      reconciliation_exempt_amount: parseFloat(reconciliation.expense_exempt_amount),
      reconciliation_tax_amount: parseFloat(reconciliation.expense_tax_amount),
      reconciliation_type: reconciliation.expense_type,
      reconciliation_balance: reconciliation.expense_balance
        ? parseFloat(reconciliation.expense_balance)
        : parseFloat(reconciliation.document_total_amount),
      reconciliation_balance_currency: reconciliation.expense_balance_currency,
      reconciliation_businessNumber: reconciliation.expense_number,
      reconciliation_date: reconciliation.expense_date,
      reconciliation_reception_date: reconciliation.expense_reception_date,
      reconciliation_description: reconciliation.expense_sender_name,
      reconciliation_currency: reconciliation.expense_currency,
      reconcilation_state: reconciliation.expense_state,
    };
  } else {
    recTemporal = {
      reconciliation_object_uid: "NO_UID",
    };
  }

  if (toObject && toObject == DocumentDomain.MOVEMENT) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_account_uid: reconciliationReference.account_uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_account_number:
        reconciliationReference.movement_account_number,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.movement_total_amount
      ),
      reconciliation_reference_type: reconciliationReference.movement_type,
      reconciliation_reference_balance: parseFloat(
        reconciliationReference.movement_balance
      ),
      reconciliation_reference_balance_currency:
        reconciliationReference.movement_balance_currency,
      reconciliation_reference_businessNumber: reconciliationReference.movement_reference,
      reconciliation_reference_date: reconciliationReference.movement_date,
      reconciliation_reference_transaction_date:
        reconciliationReference.movement_transaction_date,
      reconciliation_reference_description: reconciliationReference.movement_description,
      reconciliation_reference_currency: reconciliationReference.movement_currency,
      reconciliation_reference_origin_id: reconciliationReference.movement_origin_id,
      reconciliation_reference_original_type:
        reconciliationReference.movement_original_type,
      reconciliation_reference_pending: reconciliationReference.movement_pending,
    };
  }
  //TODO: cambiar document_sender po document_aditional_info
  else if (toObject && toObject == DocumentDomain.DOCUMENT) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_receiver_legal_id:
        reconciliationReference.document_receiver_legal_id,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.document_sender_legal_id,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.document_total_amount
      ),
      reconciliation_reference_net_amount: parseFloat(
        reconciliationReference.document_net_amount
      ),
      reconciliation_reference_exempt_amount: parseFloat(
        reconciliationReference.document_exempt_amount
      ),
      reconciliation_reference_tax_amount: parseFloat(
        reconciliationReference.document_tax_amount
      ),
      reconciliation_reference_type: reconciliationReference.document_type,
      reconciliation_reference_balance: reconciliationReference.document_balance_currency,
      reconciliation_reference_balance_currency: parseFloat(
        reconciliationReference.document_balance
      ),
      reconciliation_reference_businessNumber: reconciliationReference.document_number,
      reconciliation_reference_date: reconciliationReference.document_date,
      reconciliation_reference_reception_date:
        reconciliationReference.document_reception_date,
      reconciliation_reference_description: reconciliationReference.document_sender_name,
      reconciliation_reference_currency: reconciliationReference.document_currency,
      reconciliation_reference_state: reconciliationReference.document_state,
    };
  } else if (toObject && toObject == DocumentDomain.INVOICE) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_receiver_legal_id:
        reconciliationReference.invoice_receiver_legal_id,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.invoice_sender_legal_id,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.invoice_total_amount
      ),
      reconciliation_reference_net_amount: parseFloat(
        reconciliationReference.invoice_net_amount
      ),
      reconciliation_reference_exempt_amount: parseFloat(
        reconciliationReference.invoice_exempt_amount
      ),
      reconciliation_reference_tax_amount: parseFloat(
        reconciliationReference.invoice_tax_amount
      ),
      reconciliation_reference_type: reconciliationReference.invoice_type,
      reconciliation_reference_original_type:
        reconciliationReference.invoice_original_type,
      reconciliation_reference_balance_currency:
        reconciliationReference.invoice_balance_currency,
      reconciliation_reference_balance: parseFloat(
        reconciliationReference.invoice_balance
      ),
      reconciliation_reference_businessNumber: reconciliationReference.invoice_number,
      reconciliation_reference_date: reconciliationReference.invoice_date,
      reconciliation_reference_reception_date:
        reconciliationReference.invoice_reception_date,
      reconciliation_reference_description: reconciliationReference.invoice_sender_name,
      reconciliation_reference_currency: reconciliationReference.invoice_currency,
      reconciliation_reference_state: reconciliationReference.invoice_state,
    };
  } else if (toObject && toObject == DocumentDomain.BILL) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_receiver_legal_id:
        reconciliationReference.bill_receiver_legal_id,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.bill_sender_legal_id,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.bill_total_amount
      ),
      reconciliation_reference_net_amount: parseFloat(
        reconciliationReference.bill_net_amount
      ),
      reconciliation_reference_exempt_amount: parseFloat(
        reconciliationReference.bill_exempt_amount
      ),
      reconciliation_reference_tax_amount: parseFloat(
        reconciliationReference.bill_tax_amount
      ),
      reconciliation_reference_type: reconciliationReference.bill_type,
      reconciliation_reference_original_type: reconciliationReference.bill_original_type,
      reconciliation_reference_balance_currency:
        reconciliationReference.bill_balance_currency,
      reconciliation_reference_balance: parseFloat(reconciliationReference.bill_balance),
      reconciliation_reference_businessNumber: reconciliationReference.bill_number,
      reconciliation_reference_date: reconciliationReference.bill_date,
      reconciliation_reference_reception_date:
        reconciliationReference.bill_reception_date,
      reconciliation_reference_description: reconciliationReference.bill_sender_name,
      reconciliation_reference_currency: reconciliationReference.bill_currency,
      reconciliation_reference_state: reconciliationReference.bill_state,
    };
  } else if (toObject && toObject == DocumentDomain.EXPENSE) {
    recrefTemporal = {
      reconciliation_reference_object_uid: reconciliationReference.uid,
      reconciliation_reference_company_uid: reconciliationReference.company_uid,
      reconciliation_reference_receiver_legal_id:
        reconciliationReference.expense_receiver_legal_id,
      reconciliation_reference_sender_legal_id:
        reconciliationReference.expense_sender_legal_id,
      reconciliation_reference_total_amount: parseFloat(
        reconciliationReference.expense_total_amount
      ),
      reconciliation_reference_net_amount: parseFloat(
        reconciliationReference.expense_net_amount
      ),
      reconciliation_reference_exempt_amount: parseFloat(
        reconciliationReference.expense_exempt_amount
      ),
      reconciliation_reference_tax_amount: parseFloat(
        reconciliationReference.expense_tax_amount
      ),
      reconciliation_reference_type: reconciliationReference.expense_type,
      reconciliation_reference_original_type:
        reconciliationReference.expense_original_type,
      reconciliation_reference_balance_currency:
        reconciliationReference.expense_balance_currency,
      reconciliation_reference_balance: parseFloat(
        reconciliationReference.expense_balance
      ),
      reconciliation_reference_businessNumber: reconciliationReference.expense_number,
      reconciliation_reference_date: reconciliationReference.expense_date,
      reconciliation_reference_reception_date:
        reconciliationReference.expense_reception_date,
      reconciliation_reference_description: reconciliationReference.expense_sender_name,
      reconciliation_reference_currency: reconciliationReference.expense_currency,
      reconciliation_reference_state: reconciliationReference.expense_state,
    };
  } else {
    recrefTemporal = {
      reconciliation_reference_object_uid: "NO_UID_REF",
    };
  }

  if (
    this.invoiceAsReconciliationAddedToMovement.filter(
      (item) => item.reconciliation_reference_object_uid == reconciliationReference.uid
    ).length > 0
  ) {
    console.log("ya existe");
    alert(
      "El " +
        recrefTemporal.reconciliation_reference_type +
        " con valor " +
        recrefTemporal.reconciliation_reference_total_amount +
        " ya existe en la lista de conciliaciones seleccionadas."
    );
    return [-1, -1];
  }

  this.updateBalanceReconciliationAndReference(recTemporal, recrefTemporal);

  return [recTemporal, recrefTemporal];
}

export function objectAsReconciliation(object, fromObject) {
  //console.log("reconciliatiXon: " + JSON.stringify(object));
  //console.log("fromObject: " + fromObject);
  let recTemporal = {};

  if (fromObject && fromObject == DocumentDomain.MOVEMENT) {
    recTemporal = movementAsReconciliation(object);
  }
  //TODO: cambiar document_sender po document_aditional_info
  else if (fromObject && fromObject == DocumentDomain.DOCUMENT) {
    recTemporal = documentAsReconciliation(object);
  } else if (fromObject && fromObject == DocumentDomain.INVOICE) {
    recTemporal = invoiceAsReconciliation(object);
  } else {
    recTemporal = {
      reconciliation_object_uid: "NO_UID",
    };
  }

  return recTemporal;
}

export function movementAsReconciliation(movement) {
  return {
    reconciliation_object_uid: movement.uid,
    reconciliation_account_uid: movement.account_uid,
    reconciliation_company_uid: movement.company_uid,
    reconciliation_account_number: movement.movement_account_number,
    reconciliation_total_amount: parseFloat(movement.movement_total_amount),
    reconciliation_type: movement.movement_type,
    reconciliation_balance: movement.movement_balance
      ? parseFloat(movement.movement_balance)
      : parseFloat(movement.movement_total_amount),
    reconciliation_balance_currency: movement.movement_balance_currency,
    reconciliation_businessNumber: movement.movement_reference,
    reconciliation_date: movement.movement_date,
    reconciliation_transaction_date: movement.movement_transaction_date,
    reconciliation_description: movement.movement_description,
    reconciliation_currency: movement.movement_currency,
    reconciliation_origin_id: movement.movement_origin_id,
    reconciliation_original_type: movement.movement_original_type,
    reconciliation_pending: movement.movement_pending,
  };
}

export function documentAsReconciliation(document) {
  return {
    reconciliation_object_uid: document.uid,
    reconciliation_company_uid: document.company_uid,
    reconciliation_receiver_legal_id: document.document_receiver_legal_id,
    reconciliation_sender_legal_id: document.document_sender_legal_id,
    reconciliation_total_amount: parseFloat(document.document_total_amount),
    reconciliation_net_amount: parseFloat(document.document_net_amount),
    reconciliation_exempt_amount: parseFloat(document.document_exempt_amount),
    reconciliation_tax_amount: parseFloat(document.document_tax_amount),
    reconciliation_type: document.document_type,
    reconciliation_balance: document.document_balance
      ? parseFloat(document.document_balance)
      : parseFloat(document.document_total_amount),
    reconciliation_balance_currency: document.document_balance_currency,
    reconciliation_businessNumber: document.document_number,
    reconciliation_date: document.document_date,
    reconciliation_reception_date: document.document_reception_date,
    reconciliation_description: document.document_enterprise_name,
    reconciliation_currency: document.document_currency,
    reconcilation_state: document.document_state,
  };
}

export function invoiceAsReconciliation(invoice) {
  return {
    reconciliation_object_uid: invoice.uid,
    reconciliation_company_uid: invoice.company_uid,
    reconciliation_receiver_legal_id: invoice.invoice_receiver_legal_id,
    reconciliation_sender_legal_id: invoice.invoice_sender_legal_id,
    reconciliation_total_amount: parseFloat(invoice.invoice_total_amount),
    reconciliation_net_amount: parseFloat(invoice.invoice_net_amount),
    reconciliation_exempt_amount: parseFloat(invoice.invoice_exempt_amount),
    reconciliation_tax_amount: parseFloat(invoice.invoice_tax_amount),
    reconciliation_type: invoice.invoice_type,
    reconciliation_balance: invoice.invoice_balance
      ? parseFloat(invoice.invoice_balance)
      : parseFloat(invoice.document_total_amount),
    reconciliation_balance_currency: invoice.invoice_balance_currency,
    reconciliation_businessNumber: invoice.invoice_number,
    reconciliation_date: invoice.invoice_date,
    reconciliation_reception_date: invoice.invoice_reception_date,
    reconciliation_description: invoice.invoice_sender_name,
    reconciliation_currency: invoice.invoice_currency,
    reconcilation_state: invoice.invoice_state,
  };
}

export function expenseAsReconciliation(expense) {
  //TODO: revisar pq fue autogenerado
  return {
    reconciliation_object_uid: expense.uid,
    reconciliation_company_uid: expense.company_uid,
    reconciliation_receiver_legal_id: expense.expense_receiver_legal_id,
    reconciliation_sender_legal_id: expense.expense_sender_legal_id,
    reconciliation_total_amount: parseFloat(expense.expense_total_amount),
    reconciliation_net_amount: parseFloat(expense.expense_net_amount),
    reconciliation_exempt_amount: parseFloat(expense.expense_exempt_amount),
    reconciliation_tax_amount: parseFloat(expense.expense_tax_amount),
    reconciliation_type: expense.expense_type,
    reconciliation_balance: expense.expense_balance
      ? parseFloat(expense.expense_balance)
      : parseFloat(expense.document_total_amount),
    reconciliation_balance_currency: expense.expense_balance_currency,
    reconciliation_businessNumber: expense.expense_number,
    reconciliation_date: expense.expense_date,
    reconciliation_reception_date: expense.expense_reception_date,
    reconciliation_description: expense.expense_sender_name,
    reconciliation_currency: expense.expense_currency,
    reconcilation_state: expense.expense_state,
  };
}

export function billAsReconciliation(bill) {
  //TODO: revisar pq fue autogenerado
  return {
    reconciliation_object_uid: bill.uid,
    reconciliation_company_uid: bill.company_uid,
    reconciliation_receiver_legal_id: bill.bill_receiver_legal_id,
    reconciliation_sender_legal_id: bill.bill_sender_legal_id,
    reconciliation_total_amount: parseFloat(bill.bill_total_amount),
    reconciliation_net_amount: parseFloat(bill.bill_net_amount),
    reconciliation_exempt_amount: parseFloat(bill.bill_exempt_amount),
    reconciliation_tax_amount: parseFloat(bill.bill_tax_amount),
    reconciliation_type: bill.bill_type,
    reconciliation_balance: bill.bill_balance
      ? parseFloat(bill.bill_balance)
      : parseFloat(bill.document_total_amount),
    reconciliation_balance_currency: bill.bill_balance_currency,
    reconciliation_businessNumber: bill.bill_number,
    reconciliation_date: bill.bill_date,
    reconciliation_reception_date: bill.bill_reception_date,
    reconciliation_description: bill.bill_sender_name,
    reconciliation_currency: bill.bill_currency,
    reconcilation_state: bill.bill_state,
  };
}

export function referenceAsDocument(reference) {
  console.log(
    "reference.reconciliation_reference_enterprise_name: " +
      reference.reconciliation_reference_enterprise_name
  );
  return {
    uid: reference.reconciliation_reference_object_uid,
    company_uid: reference.reconciliation_reference_company_uid,
    document_receiver_legal_id: reference.reconciliation_reference_receiver_legal_id,
    document_sender_legal_id: reference.reconciliation_reference_sender_legal_id,
    document_total_amount: parseFloat(reference.reconciliation_reference_total_amount),
    document_net_amount: parseFloat(reference.reconciliation_reference_net_amount),
    document_exempt_amount: parseFloat(reference.reconciliation_reference_exempt_amount),
    document_tax_amount: parseFloat(reference.reconciliation_reference_tax_amount),
    document_type: reference.reconciliation_reference_type,
    document_balance: reference.reconciliation_reference_balance
      ? parseFloat(reference.reconciliation_reference_balance)
      : parseFloat(reference.reconciliation_reference_total_amount),
    document_balance_currency: reference.reconciliation_reference_balance_currency,
    document_number: reference.reconciliation_reference_businessNumber,
    document_date: reference.reconciliation_reference_date,
    document_reception_date: reference.reconciliation_reference_reception_date,
    document_enterprise_name:
      reference.reconciliation_reference_description ||
      (reference.reconciliation_reference_enterprise_name ?? ""),
    document_currency: reference.reconciliation_reference_currency,
    document_state: reference.reconciliation_reference_state,
    document_isDocument: reference.reconciliation_reference_isDocument ?? false,
    document_original_type: reference.reconciliation_reference_original_type,
  };
}

export function referenceAsInvoice(reference) {
  return {
    uid: reference.reconciliation_reference_object_uid,
    company_uid: reference.reconciliation_reference_company_uid,
    invoice_receiver_legal_id: reference.reconciliation_reference_receiver_legal_id,
    invoice_sender_legal_id: reference.reconciliation_reference_sender_legal_id,
    invoice_total_amount: parseFloat(reference.reconciliation_reference_total_amount),
    invoice_net_amount: parseFloat(reference.reconciliation_reference_net_amount),
    invoice_exempt_amount: parseFloat(reference.reconciliation_reference_exempt_amount),
    invoice_tax_amount: parseFloat(reference.reconciliation_reference_tax_amount),
    invoice_type: reference.reconciliation_reference_type,
    invoice_balance: reference.reconciliation_reference_balance
      ? parseFloat(reference.reconciliation_reference_balance)
      : parseFloat(reference.reconciliation_reference_total_amount),
    invoice_balance_currency: reference.reconciliation_reference_balance_currency,
    invoice_number: reference.reconciliation_reference_businessNumber,
    invoice_date: reference.reconciliation_reference_date,
    invoice_reception_date: reference.reconciliation_reference_reception_date,
    invoice_description: reference.reconciliation_reference_description,
    invoice_currency: reference.reconciliation_reference_currency,
    invoice_state: reference.reconciliation_reference_state,
  };
}

export function checkEmits(...eventNames) {
  let props;

  if (import.meta.env.DEV && props === getCurrentInstance()?.vnode.props) {
    for (const name of eventNames) {
      const propName = "on" + toPascalCase(name);
      if (typeof props[propName] !== "function")
        console.warn(name + " event listener is missing");
    }
  }

  return eventNames;
}

export function filterInvoices(
  invoices,
  searchTerm,
  incomesSelected,
  outcomesSelected,
  pageNumber,
  pageSize
) {
  if (searchTerm != "") {
    console.log("term: " + searchTerm);

    const docToReturn = invoices.filter((documentA) => {
      // console.log("filterLocalData:" + filterLocalData);
      if (documentA !== null && documentA !== undefined) {
        const documentDate = formatDate(documentA.invoice_date?.toLowerCase());
        const documentNumber = documentA.invoice_number?.toLowerCase();
        // console.log("documentA.invoice_receiver_legal_id?.toLowerCase(): " + documentA.invoice_receiver_legal_id?.toLowerCase());
        // console.log("documentA.invoice_sender_legal_id?.toLowerCase(): " + documentA.invoice_sender_legal_id?.toLowerCase());

        const documentReceiver = documentA.invoice_receiver_legal_id
          ?.toLowerCase()
          .replace(/\./g, "");
        const documentSender = documentA.invoice_sender_legal_id
          ?.toLowerCase()
          .replace(/\./g, "");
        const documentType = documentA.invoice_type?.toLowerCase();
        const documentTotalAmount = documentA.invoice_total_amount;
        const documentName = documentA.invoice_client_provider?.toLowerCase();
        // console.log("documentReceiver: " + documentReceiver);
        //  console.log("documentSender: " + documentSender);
        return (
          (incomesSelected &&
            documentTotalAmount >= 0 &&
            (documentDate?.includes(searchTerm) ||
              documentNumber?.includes(searchTerm) ||
              documentReceiver?.includes(searchTerm) ||
              documentSender?.includes(searchTerm) ||
              documentType?.includes(searchTerm) ||
              documentName?.includes(searchTerm) ||
              documentTotalAmount?.toString().includes(searchTerm))) ||
          (outcomesSelected &&
            documentTotalAmount < 0 &&
            (documentDate?.includes(searchTerm) ||
              documentNumber?.includes(searchTerm) ||
              documentReceiver?.includes(searchTerm) ||
              documentSender?.includes(searchTerm) ||
              documentType?.includes(searchTerm) ||
              documentName?.includes(searchTerm) ||
              documentTotalAmount?.toString().includes(searchTerm)))
        );
      }
      console.log("search temr: " + searchTerm);
    });
    return docToReturn;
  } else {
    const docToReturn = invoices.filter((documentB) => {
      const documentAmount = documentB?.invoice_total_amount;

      return (
        (incomesSelected && documentAmount >= 0) ||
        (outcomesSelected && documentAmount < 0)
      );
    });
    return docToReturn;
  }
}

export function filterDocuments(
  documents,
  searchTerm,
  incomesSelected,
  outcomesSelected,
  pageNumber,
  pageSize
) {
  if (searchTerm != "") {
    searchTerm = searchTerm.trim();
    console.log("term: " + searchTerm);

    const docToReturn = documents.filter((documentA) => {
      //console.log("documentA: " + JSON.stringify(documentA));
      if (documentA !== null && documentA !== undefined) {
        const documentDate = formatDate(documentA.document_date?.toLowerCase());
        const documentNumber = documentA.document_number?.toLowerCase();
        const documentReceiver = documentA.document_receiver_legal_id
          ?.toLowerCase()
          .replace(/\./g, "");
        const documentSender = documentA.document_sender_legal_id
          ?.toLowerCase()
          .replace(/\./g, "");
        const documentType = documentA.document_type?.toLowerCase();
        const documentTotalAmount = documentA.document_total_amount;
        const documentName = documentA.document_enterprise_name?.toLowerCase();
        const documentTypeData = documentA.document_type_data?.toLowerCase();
        return (
          (incomesSelected &&
            documentTotalAmount >= 0 &&
            (documentDate?.includes(searchTerm) ||
              documentNumber?.includes(searchTerm) ||
              documentType?.includes(searchTerm) ||
              documentName?.includes(searchTerm) ||
              documentReceiver?.includes(searchTerm) ||
              documentSender?.includes(searchTerm) ||
              documentTypeData?.includes(searchTerm) ||
              documentTotalAmount?.toString().includes(searchTerm))) ||
          (outcomesSelected &&
            documentTotalAmount < 0 &&
            (documentDate?.includes(searchTerm) ||
              documentNumber?.includes(searchTerm) ||
              documentType?.includes(searchTerm) ||
              documentName?.includes(searchTerm) ||
              documentReceiver?.includes(searchTerm) ||
              documentSender?.includes(searchTerm) ||
              documentTypeData?.includes(searchTerm) ||
              documentTotalAmount?.toString().includes(searchTerm)))
        );
      }
      console.log("search temr: " + searchTerm);
    });
    return docToReturn;
  } else {
    const docToReturn = documents.filter((documentB) => {
      const documentAmount = documentB?.document_total_amount;

      return (
        (incomesSelected && documentAmount >= 0) ||
        (outcomesSelected && documentAmount < 0)
      );
    });
    return docToReturn;
  }
}

export async function searchMovements(companyId, searchTerm) {
  if (searchTerm != "") {
    const pageMovement = await useMovementCompanySearch(myCompanyId, searchTerm);
    console.log("pageMovement search: " + JSON.stringify(pageMovement));
    let elementsDB = pageMovement.getContent();
    let totalElements = pageMovement.getTotalElements();
    console.log("count: " + totalElements);
    elementsDB = elementsDB.map((element) => ({
      ...element,
      movement_type_format: formatMovementType(
        element.movement_type,
        element.movement_original_type,
        element.movement_total_amount
      ),
      movement_assign_format: Math.abs(
        Math.abs(element.movement_total_amount) - element.movement_balance
      ),
      movement_client_provider:
        element.movement_sender +
        " " +
        formatRut(element.movement_sender_legal_id) +
        " " +
        element.movement_receiver +
        " " +
        formatRut(element.movement_receiver_legal_id),
    }));
    return elementsDB;
  }
}

export async function loadMoreMovements(
  movements,
  searchTerm,
  myCompanyId,
  incomesSelected,
  outcomesSelected,
  pageDB,
  pageSizeDB,
  pageNumber,
  pageSize
) {
  try {
    if (searchTerm != "") {
      const defaultFilter = new Filter("company_uid", "equal", myCompanyId);

      const filterAmount = new Filter("movement_amount", "contains", searchTerm);
      const filterDate = new Filter("movement_date", "equal", searchTerm);
      const filterDescription = new Filter(
        "movement_description",
        "contains",
        searchTerm
      );
      const filterComment = new Filter("movement_comment", "contains", searchTerm);
      const filterSender = new Filter("movement_sender_legal_id", "contains", searchTerm);
      const filterReceiver = new Filter(
        "movement_receiver_legal_id",
        "contains",
        searchTerm
      );

      let arrayFilters = [];
      arrayFilters.push(defaultFilter);
      arrayFilters.push(filterAmount);
      arrayFilters.push(filterDate);
      arrayFilters.push(filterDescription);
      arrayFilters.push(filterComment);
      arrayFilters.push(filterSender);
      arrayFilters.push(filterReceiver);

      const myOrder = new Order("movement_date", "desc");
      const criteria = new Criteria(pageDB, pageSizeDB, arrayFilters, myOrder);

      console.log("criteria: " + JSON.stringify(criteria));
      //const pageMovement = await useMovementFullCompanyCriteria(myCompanyId, criteria);
      //const pageMovement = await useMovementFullDBCompanyCriteria(myCompanyId, criteria);
      const pageMovement = await useMovementFullDBCompanySearch(myCompanyId, searchTerm);

      console.log("pageMovement: " + JSON.stringify(pageMovement));

      let elementsDB = pageMovement.getContent();
      let totalElements = pageMovement.getTotalElements();
      console.log("count: " + totalElements);
      elementsDB = elementsDB.map((element) => ({
        ...element,
        movement_type_format: formatMovementType(
          element.movement_type,
          element.movement_original_type,
          element.movement_total_amount
        ),
        movement_assign_format: Math.abs(
          Math.abs(element.movement_total_amount) - element.movement_balance
        ),
        movement_client_provider:
          element.movement_sender +
          " " +
          formatRut(element.movement_sender_legal_id) +
          " " +
          element.movement_receiver +
          " " +
          formatRut(element.movement_receiver_legal_id),
      }));
      return elementsDB;
    }
  } catch (error) {
    console.log("error en loadMoreMovements: " + error);
    return [];
  }
}

export async function loadMoreDocuments(
  documents,
  searchTerm,
  myCompanyData,
  incomesSelected,
  outcomesSelected,
  pageDB,
  pageSizeDB,
  pageNumber,
  pageSize
) {
  try {
    if (searchTerm != "") {
      console.log("searchTerm: " + searchTerm);
      console.log("myCompanyData.uid: " + myCompanyData.uid);

      const pageDocument = await useDocumentFullDBCompanySearch(
        myCompanyData.uid,
        searchTerm
      );

      console.log("pageDocument: " + JSON.stringify(pageDocument));

      let elementsDB = pageDocument.getContent();
      let totalElements = pageDocument.getTotalElements();
      console.log("count: " + totalElements);
      console.log("elementsDB: " + JSON.stringify(elementsDB));
      elementsDB = elementsDB.map((element) => ({
        ...element,
        document_type_data: formatDocumentFullType(element, myCompanyData),
      }));
      return elementsDB;
    }
  } catch (error) {
    console.log("error en loadMoreDocuments: " + error);
    return [];
  }
}

export function filterMovements(
  movements,
  searchTerm,
  incomesSelected,
  outcomesSelected,
  pageNumber,
  pageSize
) {
  if (searchTerm != "") {
    console.log(
      "movements length: " +
        movements.length +
        " searchTerm: " +
        searchTerm +
        " incomesSelected: " +
        incomesSelected +
        " outcomesSelected: " +
        outcomesSelected +
        " pageNumber: " +
        pageNumber +
        " pageSize: " +
        pageSize
    );
    console.log(" movements:" + JSON.stringify(movements));
    const movToReturn = movements?.filter((movementA) => {
      if (movementA !== null && movementA !== undefined) {
        const movementDate = formatDate(movementA.movement_date.toLowerCase());
        const movementType = movementA.movement_type.toLowerCase();
        const movementDescription = movementA.movement_description?.toLowerCase();
        const movementTotalAmount = movementA.movement_total_amount;
        const movementAssignAmount =
          Math.abs(movementA.movement_total_amount) -
          Math.abs(movementA.movement_balance);
        const movementBalance = movementA.movement_balance;
        const movementClientProvider = movementA.movement_client_provider
          ?.toLowerCase()
          .replace(/\./g, "");
        const movementTypeFormat = movementA.movement_type_format?.toLowerCase();

        return (
          (incomesSelected &&
            movementTotalAmount >= 0 &&
            (movementDate?.includes(searchTerm) ||
              movementType?.includes(searchTerm) ||
              movementDescription?.includes(searchTerm) ||
              movementTotalAmount?.toString().includes(searchTerm) ||
              movementAssignAmount?.toString().includes(searchTerm) ||
              movementClientProvider?.toString().includes(searchTerm) ||
              movementTypeFormat?.toString().includes(searchTerm) ||
              movementBalance?.toString().includes(searchTerm))) ||
          (outcomesSelected &&
            movementTotalAmount < 0 &&
            (movementDate?.includes(searchTerm) ||
              movementType?.includes(searchTerm) ||
              movementDescription?.includes(searchTerm) ||
              movementTotalAmount?.toString().includes(searchTerm) ||
              movementAssignAmount?.toString().includes(searchTerm) ||
              movementClientProvider?.toString().includes(searchTerm) ||
              movementTypeFormat?.toString().includes(searchTerm) ||
              movementBalance?.toString().includes(searchTerm)))
        );
      }
    });
    return movToReturn;
  } else {
    const movToReturn = movements?.filter((movementB) => {
      const movementAmount = movementB?.movement_total_amount;

      return (
        (incomesSelected && movementAmount >= 0) ||
        (outcomesSelected && movementAmount < 0)
      );
    });
    return movToReturn;
  }
}

export function getFiltersForMovement(companyId, pageDB, pageSizeDB) {
  const defaultFilter = new Filter("company_uid", "=", companyId);
  const filterBalance = new Filter("movement_balance", ">", 0);
  let arrayFilters = [];
  arrayFilters.push(defaultFilter);
  arrayFilters.push(filterBalance);
  const criteria = new Criteria(pageDB, pageSizeDB, arrayFilters);
  return criteria;
}

export function getFiltersForDocument(companyId, pageDB, pageSizeDB) {
  const defaultFilter = new Filter("company_uid", "=", companyId);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);

  const criteria = new Criteria(pageDB, pageSizeDB, arrayFilters);
  return criteria;
}

export function getFiltersForExpense(companyId, pageDB, pageSizeDB) {
  const defaultFilter = new Filter("company_uid", "=", companyId);
  const expenseFilter = new Filter("document_type", "=", DocumentDomain.EXPENSE);

  let arrayFilters = [];
  arrayFilters.push(defaultFilter);
  arrayFilters.push(expenseFilter);

  const criteria = new Criteria(pageDB, pageSizeDB, arrayFilters);
  return criteria;
}
</script>
