export class Order {
  readonly orderBy: string;
  readonly orderType: string;

  constructor(orderBy: string, orderType: string) {
    this.orderBy = orderBy;
    this.orderType = orderType;
  }

  static fromValues(orderBy?: string, orderType?: string): Order {
    if (!orderBy) {
      return Order.none();
    }

    return new Order(orderBy, orderType || "asc");
  }

  static none(): Order {
    return new Order("", "none");
  }

  static desc(orderBy: string): Order {
    return new Order(orderBy, "desc");
  }

  static asc(orderBy: string): Order {
    return new Order(orderBy, "asc");
  }

  public hasOrder() {
    return this.orderType !== "none";
  }
}
