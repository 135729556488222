import { Document } from "~/context/Documents/domain/Document";
import { listMovementsByCompanyDistanceAndCriteria, listMovementsFullByCompanyDistanceAndCriteriaWithDocument, listMovementsFullByCompanyDistanceAndCriteriaWithMovement, listMovementsFullRepositoryByCompanyAndSearch } from "~/context/Movements/application/listMovements";
import { Movement } from "~/context/Movements/domain/Movement";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";

export function useMovementCompanyDistanceCriteria(companyId: string, documentId: string, type: string, criteria: Criteria) {
  const retMovements = listMovementsByCompanyDistanceAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, documentId, type, criteria);
  return retMovements;
}

export function useMovementCompanyDistanceCriteriaWithDocument(companyId: string, document: Document, criteria: Criteria) {

  console.log("dentro de useMovementCompanyDistanceCriteriaWithDocument: " + companyId + " criteria: " + JSON.stringify(criteria));
  // const retMovements = listMovementsByCompanyDistanceAndCriteriaWithDocument(Dependencies.getInstance().getMovementRepository(), companyId, document, criteria);
  const retMovements = listMovementsFullByCompanyDistanceAndCriteriaWithDocument(Dependencies.getInstance().getMovementRepository(), companyId, document, criteria);

  return retMovements;
}

export function useMovementCompanyDistanceCriteriaWithMovement(companyId: string, movement: Movement, criteria: Criteria) {

  console.log("dentro de useMovementCompanyDistanceCriteriaWithMovement: " + companyId + " criteria: " + JSON.stringify(criteria));
  // const retMovements = listMovementsByCompanyDistanceAndCriteriaWithDocument(Dependencies.getInstance().getMovementRepository(), companyId, document, criteria);
  const retMovements = listMovementsFullByCompanyDistanceAndCriteriaWithMovement(Dependencies.getInstance().getMovementRepository(), companyId, movement, criteria);
  //listMovementsFullByCompanyDistanceAndCriteriaWithDocument(Dependencies.getInstance().getMovementRepository(), companyId, document, criteria);

  return retMovements;
}



export function useMovementCompanySearch(companyId: string, search: string) {
  const retMovements = listMovementsFullRepositoryByCompanyAndSearch(Dependencies.getInstance().getMovementRepository(), companyId, search);
  return retMovements;
}


