import { listInvoicesByCompanyAndCriteria, listInvoicesByCompanyDistanceAndCriteria } from "~/context/Invoices/application/listInvoices";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";

export function useInvoiceCompanyCriteria(companyId: string, criteria: Criteria) {

  const retInvoices = listInvoicesByCompanyAndCriteria(Dependencies.getInstance().getInvoiceRepository(), companyId, criteria);

  return retInvoices;
}

export function useInvoiceCompanyDistanceCriteria(companyId: string, movementId: string, criteria: Criteria) {
  const retInvoices = listInvoicesByCompanyDistanceAndCriteria(Dependencies.getInstance().getInvoiceRepository(), companyId, movementId, criteria);
  return retInvoices;
}




