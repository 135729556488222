import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";
import { listMovementsByAccountUid, listMovementsByAccountCompanyAndCriteria, listMovementsByCompanyAndCriteria, listMovementsWithCompanyByCompanyAndCriteria, listMovementsWithReconciliationsByCompanyAndCriteria, listMovementsFullByCompanyAndCriteria, listMovementsFullRepositoryByCompanyAndCriteria, listMovementsFullRepositoryByCompanyAndSearch, listMovementsFullWithReconciliationsByCompanyAndCriteria } from "../context/Movements/application/listMovements";
import { getMovementByMovementUid } from "~/context/Movements/application/getMovementByMovementId";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";


export function useMovementAccounts(accountId: string) {
  console.log("dentro de useMovementAccounts!!!");
  const retMovements = listMovementsByAccountUid(Dependencies.getInstance().getMovementRepository(), accountId);
  return retMovements;
}

export function useMovementCompanyAccountCriteria(companyId: string, accountId: string, criteria: Criteria) {
  console.log("dentro de useMovementCompanyAccountCriteria");
  const retMovements = listMovementsByAccountCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, accountId, criteria);
  return retMovements;
}

export function useMovementWithCompanyCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementWithCompanyCompanyCriteria: companyId: ", companyId, " criteria", JSON.stringify(criteria));
  const retMovements = listMovementsWithCompanyByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  return retMovements;
}


export function useMovementCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementCompanyCriteria: companyId: ", companyId, " criteria", JSON.stringify(criteria));
  const retMovements = listMovementsByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  return retMovements;
}

export function useMovementFullCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementFullCompanyCriteria: companyId: ", companyId, " criteria", JSON.stringify(criteria));
  const retMovements = listMovementsFullByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  return retMovements;
}

export function useMovementFullDBCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementFullDBCompanyCriteria: companyId: ", companyId, " criteria", JSON.stringify(criteria));
  const retMovements = listMovementsFullRepositoryByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  return retMovements;
}

export function useMovementFullDBCompanySearch(companyId: string, search: string) {
  try {
    console.log("dentro de useMovementFullDBCompanySearch: companyId: ", companyId, " search", JSON.stringify(search));
    const retMovements = listMovementsFullRepositoryByCompanyAndSearch(Dependencies.getInstance().getMovementRepository(), companyId, search);
    return retMovements;
  } catch (error) {
    console.log("error en useMovementFullDBCompanySearch: ", error);
  }

}



export function useMovementWithReconciliationCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementWithReconciliationCompanyCriteria: companyId: ", companyId, " criteria: ", JSON.stringify(criteria));
  const retMovements = listMovementsWithReconciliationsByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  console.log(JSON.stringify(retMovements));
  return retMovements;
}


export function useMovementFullWithReconciliationCompanyCriteria(companyId: string, criteria: Criteria) {
  console.log("dentro de useMovementFullWithReconciliationCompanyCriteria: companyId: ", companyId, " criteria: ", JSON.stringify(criteria));
  const retMovements = listMovementsFullWithReconciliationsByCompanyAndCriteria(Dependencies.getInstance().getMovementRepository(), companyId, criteria);
  console.log(JSON.stringify(retMovements));
  return retMovements;
}


export function useMovement(movementId: string) {
  console.log("dentro de useMovement");
  const retMovement = getMovementByMovementUid(Dependencies.getInstance().getMovementRepository(), movementId);
  return retMovement;
}
