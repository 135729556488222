import { getDocumentByDocumentUidAndType } from "~/context/Documents/application/getDocumentByDocumentId";
import { listDocumentsByCompanyAndCriteria, listFullOutcomeDocumentsByCompanyEmployeeAndCriteria, listFullOutcomeDocumentsByCompanyProviderAndCriteria, listSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria } from "~/context/Documents/application/listDocuments";
import { listDocumentsByCompanyDistanceAndCriteria } from "~/context/Documents/application/listDocuments";
import { listDocumentsByCompanyDistanceAndCriteriaWithMovement } from "~/context/Documents/application/listDocuments";
import { listFullDocumentsByCompanyAndCriteria } from "~/context/Documents/application/listDocuments";
import { listFullIncomeDocumentsByCompanyAndCriteria } from "~/context/Documents/application/listDocuments";
import { listFullIncomeDocumentsByCompanyClientAndCriteria } from "~/context/Documents/application/listDocuments";
import { listFullOutcomeDocumentsByCompanyAndCriteria } from "~/context/Documents/application/listDocuments";
import { listDocumentsFullRepositoryByCompanyAndSearch } from "~/context/Documents/application/listDocuments";
import { Document } from "~/context/Documents/domain/Document";
import { Movement } from "~/context/Movements/domain/Movement";
import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Dependencies } from "~/context/Shared/infrastructure/Dependencies";

export function useDocumentCompanyCriteria(companyId: string, criteria: Criteria) {
  const retDocuments = listDocumentsByCompanyAndCriteria(Dependencies.getInstance().getDocumentRepository(), companyId, criteria);
  return retDocuments;
}

export function useDocument(documentId: string, document_type: string) {
  console.log("dentro de useDocument: " + documentId + " document_type:" + document_type);
  const retDocument = getDocumentByDocumentUidAndType(Dependencies.getInstance().getDocumentRepository(), documentId, document_type);
  return retDocument;
}

export function useDocumentCompanyDistanceCriteria(companyId: string, movementId: string, criteria: Criteria) {
  const retDocument = listDocumentsByCompanyDistanceAndCriteria(Dependencies.getInstance().getDocumentRepository(), companyId, movementId, criteria);
  return retDocument;
}

export function useDocumentCompanyDistanceCriteriaWithMovement(companyId: string, movement: Movement, criteria: Criteria) {
  const retDocument = listDocumentsByCompanyDistanceAndCriteriaWithMovement(Dependencies.getInstance().getDocumentRepository(), companyId, movement, criteria);
  return retDocument;
}



export function useFullDocumentCompanyCriteria(companyId: string, criteria: Criteria) {
  const retDocuments = listFullDocumentsByCompanyAndCriteria(Dependencies.getInstance().getDocumentRepository(), companyId, criteria);
  return retDocuments;
}

export function useFullIncomeDocumentCompanyCriteria(companyId: string, criteria: Criteria) {
  const retDocuments = listFullIncomeDocumentsByCompanyAndCriteria(Dependencies.getInstance().getDocumentRepository(), companyId, criteria);
  return retDocuments;
}

export function useFullOutcomeDocumentCompanyCriteria(companyId: string, criteria: Criteria) {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  const retDocuments = listFullOutcomeDocumentsByCompanyAndCriteria(documentRepository, companyId, criteria);
  return retDocuments;
}

export function useFullIncomeDocumentCompanyClientCriteria(companyId: string, clientId: string, criteria: Criteria) {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  const retDocuments = listFullIncomeDocumentsByCompanyClientAndCriteria(documentRepository, companyId, clientId, criteria);
  return retDocuments;
}

export function useFullOutcomeDocumentCompanyProviderCriteria(companyId: string, providerId: string, criteria: Criteria) {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  const retDocuments = listFullOutcomeDocumentsByCompanyProviderAndCriteria(documentRepository, companyId, providerId, criteria);
  return retDocuments;
}


export function useFullOutcomeDocumentCompanyEmployeeCriteria(companyId: string, employeeId: string, criteria: Criteria) {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  const retDocuments = listFullOutcomeDocumentsByCompanyEmployeeAndCriteria(documentRepository, companyId, employeeId, criteria);
  return retDocuments;
}

export async function useSplitFullOutcomeDocumentCompanyEmployeeCriteria(companyId: string, employeeId: string, criteria: Criteria) {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  const [retDocument, retDocument2] = await listSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria(documentRepository, companyId, employeeId, criteria);
  console.log("retDocument: ", JSON.stringify(retDocument), " retDocument2: ", JSON.stringify(retDocument2));
  return [retDocument, retDocument2];
}



export async function findDocumentBySpotExpenseId(spotExpenseId: string, companyId: string): Promise<Document> {
  const documentRepository = Dependencies.getInstance().getDocumentRepository();
  console.log("dentro de findDocumentBySpotExpenseId: ", spotExpenseId, " companyId: ", companyId);
  const document = await documentRepository.getDocumentBySpotExpenseId(spotExpenseId, companyId);
  return document;
}






export function useDocumentFullDBCompanySearch(companyId: string, search: string) {
  try {
    console.log("dentro de useDocumentFullDBCompanySearch: companyId: ", companyId, " search", JSON.stringify(search));
    const retDocuments = listDocumentsFullRepositoryByCompanyAndSearch(Dependencies.getInstance().getDocumentRepository(), companyId, search);
    return retDocuments;
  } catch (error) {
    console.log("error en useDocumentFullDBCompanySearch: ", error);
  }

}






