export class DocumentDomainSII {
  static FACTURAFISICA: string = "30";
  static FACTURAFISICAVENTA: string = "32";
  static FACTURAELECTRONICA: string = "33";
  static FACTURAELECTRONICAVENTA: string = "34";
  static FACTURAFISICACOMPRA: string = "45";
  static FACTURAELECTRONICACOMPRA: string = "46";
  static NOTADEDEBITOFISICA: string = "55";
  static NOTADEBITOELECTRONICA: string = "56";
  static NOTACREDITOFISICA: string = "60";
  static NOTACREDITOELECTRONICA: string = "61";
  static FACTURADECOMPRAELECTRONICA: string = "46";
  static FACTURAEXPORTACION: string = "110";
  static EMITIDO: string = "Emitido";
  static RECHAZADO: string = "Rechazado";
  static RECLAMADO: string = "Reclamado";
  static REGISTRO: string = "Registro";

}