import { Movement } from "../domain/Movement";
import { MovementId } from "../domain/MovementId";
import { MovementRepository } from "../domain/MovementRepository";

export async function getMovementByMovementUid(
  movementRepository: MovementRepository,
  movementUid: string
): Promise<Movement> {
  const movementId = new MovementId(movementUid);
  const movement = await movementRepository.getMovementsByMovementUid(movementId.toString());
  if (movement === undefined) {
    throw new Error("Movement not found");
  } else {
    return movement;
  }
}
