import { v4 as uuid } from "uuid";

export class MovementId {
  readonly value: string;
  constructor(value: string) {
    this.value = value;
  }

  toString(): string {
    return this.value;
  }

  static random(): MovementId {
    return new MovementId(uuid());
  }
}
