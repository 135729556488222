import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { DocumentRepository } from "../domain/DocumentRepository";
import { Page } from "~/context/Shared/domain/page/Page";
import { Document } from "../domain/Document";
import { PageFull } from "~/context/Shared/domain/page/PageFull";
import { Movement } from "~/context/Movements/domain/Movement";


export function listDocumentsByCompanyAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Document>> {
  console.log("dentro de listDocumentsByCompanyAndCriteria : company_uid: ", company_uid, " criteria: ", criteria);
  return documentRepository.getDocumentsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listDocumentsByCompanyDistanceAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  movement_uid: string,
  criteria: Criteria
): Promise<Page<Document>> {
  console.log("dentro de listDocumentsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " movement_uid: ", movement_uid);
  return documentRepository.getDocumentsByCompanyDistanceAndCriteria(
    company_uid,
    movement_uid,
    criteria
  );
}

export async function listDocumentsByCompanyDistanceAndCriteriaWithMovement(
  documentRepository: DocumentRepository,
  company_uid: string,
  movement: Movement,
  criteria: Criteria
): Promise<Page<Document>> {
  try {
    const movementId = movement.uid;
    console.log("dentro de listDocumentsByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " movement_uid: ", movementId);
    const ret = await documentRepository.getDocumentsByCompanyDistanceAndCriteria(company_uid, movementId.toString(), criteria);
    const documents = orderByDifference(ret, movement);
    // console.log("retorno listDocumentsByCompanyDistanceAndCriteriaWithMovement: " + JSON.stringify(documents));
    return new Page<Document>(documents.length, documents);
  } catch (error) {
    console.log("error en listDocumentsByCompanyDistanceAndCriteriaWithMovement: ", error);
    return new Page<Document>(0, []);
  }

}

function orderByDifference(response: Page<Document>, movement: Movement): Document[] {
  console.log("dentro de orderByDifference" + response.content.length);
  const pageDocument = response.content.map((doc) => {
    //  console.log("doc: ", JSON.stringify(doc));
    //  console.log("movement: ", movement.movement_total_amount);
    //  console.log("doc.document_total_amount: ", doc.document_total_amount);
    const movementBalance = movement.movement_balance || 0;
    const documentBalance = doc.document_balance || 0;
    //  console.log("movementBalance: ", movementBalance);
    // console.log("documentBalance: ", documentBalance);

    //    console.log("difference: ", Math.abs(documentBalance - movementBalance));
    return ({
      ...doc,
      difference: Math.abs(documentBalance - movementBalance)
    })
  })
    .sort((a, b) => a.difference - b.difference)
    .map((doc) => doc);
  return pageDocument;

}


export function listFullDocumentsByCompanyAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  criteria: Criteria
): Promise<PageFull<Document>> {
  console.log("dentro de listFullDocumentsByCompanyAndCriteria : company_uid: ", company_uid, " criteria: ", criteria);
  return documentRepository.getFullDocumentsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listFullIncomeDocumentsByCompanyAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  criteria: Criteria
): Promise<PageFull<Document>> {
  console.log("dentro de listFullIncomeDocumentsByCompanyAndCriteria : company_uid: ", company_uid, " criteria: ", criteria);
  return documentRepository.getFullIncomeDocumentsByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listFullIncomeDocumentsByCompanyClientAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  client_uid: string,
  criteria: Criteria
): Promise<PageFull<Document>> {
  console.log("dentro de listFullIncomeDocumentsByCompanyClientAndCriteria : company_uid: " + company_uid + " client_uid: " + client_uid + " criteria: " + JSON.stringify(criteria));
  return documentRepository.getFullIncomeDocumentsByCompanyClientAndCriteria(
    company_uid,
    client_uid,
    criteria
  );
}

export function listFullOutcomeDocumentsByCompanyProviderAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  provider_uid: string,
  criteria: Criteria
): Promise<PageFull<Document>> {
  console.log("dentro de listFullOutcomeDocumentsByCompanyProviderAndCriteria : company_uid: " + company_uid + " provider_uid: " + provider_uid + " criteria: " + JSON.stringify(criteria));
  return documentRepository.getFullOutcomeDocumentsByCompanyProviderAndCriteria(
    company_uid,
    provider_uid,
    criteria
  );
}

export function listFullOutcomeDocumentsByCompanyEmployeeAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  employee_uid: string,
  criteria: Criteria
): Promise<PageFull<Document>> {
  console.log("dentro de listFullOutcomeDocumentsByCompanyProviderAndCriteria : company_uid: " + company_uid + " employee_uid: " + employee_uid + " criteria: " + JSON.stringify(criteria));
  return documentRepository.getFullOutcomeDocumentsByCompanyEmployeeAndCriteria(
    company_uid,
    employee_uid,
    criteria
  );
}

export function listSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  employee_uid: string,
  criteria: Criteria
): Promise<[PageFull<Document>, PageFull<Document>]> {
  console.log("dentro de listSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria : company_uid: " + company_uid + " employee_uid: " + employee_uid + " criteria: " + JSON.stringify(criteria));
  return documentRepository.getSplitFullOutcomeDocumentsByCompanyEmployeeAndCriteria(
    company_uid,
    employee_uid,
    criteria
  );
}




export async function listFullOutcomeDocumentsByCompanyAndCriteria(
  documentRepository: DocumentRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Document>> {
  console.log("dentro de listFullOutcomeDocumentsByCompanyAndCriteria : company_uid: ", company_uid, " criteria: ", criteria);
  const ret = await documentRepository.getFullOutcomeDocumentsByCompanyAndCriteria(
    company_uid,
    criteria
  );
  console.log("retorno listFullOutcomeDocumentsByCompanyAndCriteria: " + JSON.stringify(ret));
  return ret;
}


export function listDocumentsFullRepositoryByCompanyAndSearch(
  documentRepository: DocumentRepository,
  company_uid: string,
  search: string
): Promise<Page<Document>> {
  const ret = documentRepository.getDocumentsFullRepositoryByCompanyAndSearch(
    company_uid,
    search
  );
  console.log("listMovementsRepositoryByCompanyAndSearch: ", JSON.stringify(ret));
  return ret;
}

