import { Criteria } from "~/context/Shared/domain/criteria/Criteria";
import { Invoice } from "../domain/Invoice";
import { InvoiceRepository } from "../domain/InvoiceRepository";
import { Page } from "~/context/Shared/domain/page/Page";



export function listInvoicesByCompanyAndCriteria(
  invoiceRepository: InvoiceRepository,
  company_uid: string,
  criteria: Criteria
): Promise<Page<Invoice>> {
  console.log("dentro de listInvoicesByCompanyAndCriteria : company_uid: ", company_uid, " criteria: ", criteria);
  return invoiceRepository.getInvoicesByCompanyAndCriteria(
    company_uid,
    criteria
  );
}

export function listInvoicesByCompanyDistanceAndCriteria(
  invoiceRepository: InvoiceRepository,
  company_uid: string,
  movement_uid: string,
  criteria: Criteria
): Promise<Page<Invoice>> {
  console.log("dentro de listInvoicesByCompanyDistanceAndCriteria : company_uid: ", company_uid, " criteria: ", criteria, " movement_uid: ", movement_uid);
  return invoiceRepository.getInvoicesByCompanyDistanceAndCriteria(
    company_uid,
    movement_uid,
    criteria
  );
}





export function listInvoices(
  _invoiceRepository: InvoiceRepository
): Promise<Invoice[]> {
  throw new Error("Not implemented");
}



export function getInvoicesByReceiverTaxId(
  _invoiceRepository: InvoiceRepository,
  _receiverTaxId: string
): Promise<Invoice[]> {
  //return invoiceRepository.getInvoicesByReceiverTaxId(receiverTaxId);
  throw new Error("Not implemented");
}

export function getAvailableInvoicesByReceiverTaxId(
  _invoiceRepository: InvoiceRepository,
  _receiverTaxId: string
): Promise<Invoice[]> {
  //return invoiceRepository.getAvailableInvoicesByReceiverTaxId(receiverTaxId);
  throw new Error("Not implemented");
}

export function getInvoiceByInvoiceUid(
  _invoiceRepository: InvoiceRepository,
  _invoiceUid: string
): Promise<Invoice | undefined> {
  //console.log("haciendo get invoice:" + invoiceUid);
  //return invoiceRepository.getInvoiceByInvoiceUid(invoiceUid);
  throw new Error("Not implemented");
}
