import { DocumentDomain } from "~/context/Shared/domain/DocumentDomain";
import { DocumentDomainSII } from "~/context/Shared/domain/DocumentDomainSII";
import { MovementDomainFintoc } from "~/context/Shared/domain/MovementDomainFintoc";
import moment from "moment";
import levenshtein from 'fast-levenshtein';
import { referenceAsDocument } from "~/utils/functions.vue";


export function formatMoneyLatam(value) {
  if (value) {
    if (parseInt(value) == 0 || isNaN(parseInt(value) || value == '0')) {
      return 0;
    }
    return parseInt(value).toLocaleString('es-CL');
  }
  return 0;
}

export function formatScreenMoneyLatam(value) {
  let screenSize = window.innerWidth;
  console.log("screenSize: " + screenSize);
  if (value) {
    if (parseInt(value) == 0 || isNaN(parseInt(value) || value == '0')) {
      return 0;
    } else {
      if (screenSize > 500) {
        return parseInt(value).toLocaleString('es-CL');
      } else {
        console.log("pantalla pequeña");
        let dataRet = Math.round((parseInt(value) / 1000)) + "K";
        return dataRet;
      }

    }

  }
  return 0;
}


export function cleanDotsAndDash(value) {
  if (value) {
    return value.replace(/[.-]/g, '').toUpperCase();
  }
  return "";
}


export function calculateDistanceDocumentMovement(document, movement, legalId) {
  //console.log("movement: " + JSON.stringify(movement));
  //console.log("document: " + JSON.stringify(document));
  //console.log("legalId: " + legalId);
  // const distance = Math.abs(movement.movement_total_amount - invoice.invoice_total_amount);
  // let distanceSender = 0;
  // let distanceDescription = 0;
  // let distanceLegalId = 0;
  let containsLegalId = false;


  if (formatRut(legalId) === formatRut(document.document_sender_legal_id)) {
    containsLegalId = cleanDotsAndDash(movement.movement_client_provider).includes(cleanDotsAndDash(document.document_receiver_legal_id)) || cleanDotsAndDash(movement.movement_description).includes(cleanDotsAndDash(document.document_receiver_legal_id));
  } else if (formatRut(legalId) === formatRut(document.document_receiver_legal_id)) {

    containsLegalId = cleanDotsAndDash(movement.movement_client_provider).includes(cleanDotsAndDash(document.document_sender_legal_id)) || cleanDotsAndDash(movement.movement_description).includes(cleanDotsAndDash(document.document_sender_legal_id));
  }
  return containsLegalId;
}

export function calculateDistance(invoice, movement, legalId) {
  // console.log("movement: " + JSON.stringify(movement));
  //console.log("invoice: " + JSON.stringify(invoice));
  //console.log("legalId: " + legalId);
  // const distance = Math.abs(movement.movement_total_amount - invoice.invoice_total_amount);
  // let distanceSender = 0;
  // let distanceDescription = 0;
  // let distanceLegalId = 0;
  let containsLegalId = false;

  if (formatRut(legalId) === formatRut(invoice.invoice_sender_legal_id)) {
    containsLegalId = cleanDotsAndDash(movement.movement_client_provider).includes(cleanDotsAndDash(invoice.invoice_receiver_legal_id)) || cleanDotsAndDash(movement.movement_description).includes(cleanDotsAndDash(invoice.invoice_receiver_legal_id));
  } else if (formatRut(legalId) === formatRut(invoice.invoice_receiver_legal_id)) {
    containsLegalId = cleanDotsAndDash(movement.movement_client_provider).includes(cleanDotsAndDash(invoice.invoice_sender_legal_id)) || cleanDotsAndDash(movement.movement_description).includes(cleanDotsAndDash(invoice.invoice_sender_legal_id));
  }
  return containsLegalId;
  /*
    console.log("containsLegalId: " + containsLegalId);
  
  
  
    if (formatRut(legalId) === formatRut(invoice.invoice_receiver_legal_id)) {
      distanceSender = levenshtein.get(movement.movement_client_provider, invoice.invoice_sender_name);
      distanceDescription = levenshtein.get(movement.movement_description, invoice.invoice_sender_name);
      console.log("distanceSender A: " + distanceSender);
      console.log("distanceDescription A: " + distanceDescription);
    } else if (formatRut(legalId) === formatRut(invoice.invoice_sender_legal_id)) {
      distanceSender = levenshtein.get(movement.movement_client_provider, invoice.invoice_sender_name);
      distanceDescription = levenshtein.get(movement.movement_description, invoice.invoice_sender_name);
      distanceLegalId = levenshtein.get(movement.movement_client_provider, invoice.invoice_client_provider);
      console.log("distanceSender B: " + distanceSender);
      console.log("distanceDescription B: " + distanceDescription);
      console.log("distanceLegalId B: " + distanceLegalId);
  
    } else {
  
    }
    return distance + distanceSender + distanceDescription;
  */

};


export function validateRut(rut) {
  //console.log("validateRut: " + rut);
  // Eliminar puntos, guiones y convertir a mayúsculas
  rut = rut.replace(/[^\dK]/g, '').toUpperCase();

  // Validar largo mínimo
  if (rut.length < 7) return false;

  // Extraer el cuerpo y el dígito verificador
  const body = rut.slice(0, -1);
  const bodyReverse = body.split('').reverse();
  const verifier = rut.slice(-1);

  // Calcular el dígito verificador
  let sum = 0;

  const factors = [2, 3, 4, 5, 6, 7];
  for (let i = 0; i < bodyReverse.length; i++) {
    sum += parseInt(bodyReverse[i]) * factors[i % factors.length];
  }

  const calculatedVerifier = 11 - (sum % 11);
  const calculatedVerifierChar = calculatedVerifier === 11 ? '0' : calculatedVerifier === 10 ? 'K' : calculatedVerifier.toString();
  // Comparar el dígito verificador calculado con el dígito verificador del RUT
  return verifier === calculatedVerifierChar;
}


export function formatRut(value) {
  let formattedRut = "";
  if (value) {
    // Eliminar puntos y guiones del RUT y convertir a mayúsculas        
    let rut = value.replace(/\./g, '').replace(/-/g, '').toUpperCase();
    // Separar el RUT en número y dígito verificador
    const rutNumber = rut.slice(0, -1);
    const verificador = rut.slice(-1);
    // Agregar puntos al número del RUT
    formattedRut = rutNumber.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

    // Agregar guión y dígito verificador
    formattedRut = formattedRut + "-" + verificador;
  }
  return formattedRut;
}

export function formatEnterpriseSuffix(text) {

  const regex = /(.*?)(\s+)?(spa|limitada|ltda|sa|s\.?a\.?\.?|s\.?a\.?)$/i;

  // Reemplazar el sufijo empresarial con una cadena vacía
  if (text) {
    return text.replace(regex, '$1').trim();
  } else {
    return "";
  }
}

export function reconciliationReferenceType(recReference, company) {
  let objectType = recReference.reconciliation_reference_type;
  let objectOriginType = recReference.reconciliation_reference_original_type;
  if (objectType === DocumentDomain.MOVEMENT) {
    console.log("recReference.reconciliation_reference_amount: " + recReference.reconciliation_reference_total_amount);
    return formatMovementType(objectType, objectOriginType, recReference.reconciliation_reference_total_amount)
  } else {
    const document = referenceAsDocument(recReference)
    return formatDocumentFullType(document, company)
  }
}

export function isDocumentIncome(document, company) {
  if (document.document_type === DocumentDomain.INVOICE && formatRut(document.document_receiver_legal_id) === formatRut(company.company_legal_id)) {
    return false;
  } else if (document.document_type === DocumentDomain.INVOICE && formatRut(document.document_sender_legal_id) === formatRut(company.company_legal_id)) {
    return true;

  } else if (document.document_type === DocumentDomain.BILL && formatRut(document.document_sender_legal_id) === formatRut(company.company_legal_id)) {
    return true;
  }
  else {
    return false;
  }
}

export function formatMovementType(movementType, movementOriginalType, movementAmount) {
  let formatMovementType = "";
  //console.log("movement:" + movementType);
  if (movementType) {
    switch (movementType) {
      case DocumentDomain.MOVEMENT:
        if (movementAmount > 0) {
          formatMovementType = "Abono";
        } else {
          formatMovementType = "Egreso";
        }
        break;
      default:
        formatMovementType = movementType;
    }
  }
  if (movementOriginalType) {
    switch (movementOriginalType) {
      case MovementDomainFintoc.TRANSFER:
        formatMovementType += " Transferencia";
        break;
      case MovementDomainFintoc.CHECK:
        formatMovementType += " Cheque";
        break;
      case MovementDomainFintoc.OTHER:
        formatMovementType += " Otro";
        break;
      default:
        formatMovementType = movementOriginalType;
    }
  }
  return formatMovementType;
}

export function formatData(data) {
  if (data !== null || data !== undefined || data !== "undefined" || data !== "") {
    return data;
  }
  return "-";
}

export function formatReconciliationType(reference, company) {

  let documentType = reference.reconciliation_reference_type;
  let documentOriginType = reference.reconciliation_reference_original_type;
  let documentState = reference.reconciliation_reference_state;
  let formatDocument = formatDocumentType(documentType, documentOriginType, documentState);


  if (documentType === DocumentDomain.INVOICE && formatRut(reference.document_receiver_legal_id) === formatRut(company.company_legal_id)) {
    return formatDocument + " Proveedor";
  } else if (documentType === DocumentDomain.INVOICE && formatRut(reference.document_sender_legal_id) === formatRut(company.company_legal_id)) {
    return formatDocument + " Venta";
  } else {
    return formatDocument;
  }


}

export function isNC(document, company){
console.log("document_original_type: " + document.document_original_type);
console.log("cond 1: " + (document.document_original_type===DocumentDomainSII.NOTACREDITOFISICA));
console.log("cond 2: " + (document.document_original_type===DocumentDomainSII.NOTACREDITOELECTRONICA));
console.log("cond 3: " + (document.document_type===DocumentDomain.INVOICE));
console.log("cond 4a: " + formatRut(document.document_receiver_legal_id));
console.log("cond 4b: " + formatRut(company.company_legal_id));
console.log("cond 4: " + (formatRut(document.document_receiver_legal_id)===formatRut(company.company_legal_id)));

if(document.document_original_type===DocumentDomainSII.NOTACREDITOFISICA || document.document_original_type===DocumentDomainSII.NOTACREDITOELECTRONICA){

  if (document.document_type === DocumentDomain.INVOICE && formatRut(document.document_sender_legal_id) === formatRut(company.company_legal_id)) {
    return true;
  }

}else{
  return false;
}

}


export function formatDocumentFullType(document, company) {
  let documentType = document.document_type;
  let documentOriginType = document.document_original_type;
  let documentState = document.document_state;
  //console.log("document-->\n" + JSON.stringify(document));
  //console.log("company-->\n" + JSON.stringify(company));
  let formatDocument = formatDocumentType(documentType, documentOriginType, documentState);

  if (documentType === DocumentDomain.INVOICE && formatRut(document.document_receiver_legal_id) === formatRut(company.company_legal_id)) {
    return formatDocument + " Proveedor";
  } else if (documentType === DocumentDomain.INVOICE && formatRut(document.document_sender_legal_id) === formatRut(company.company_legal_id)) {
    return formatDocument + " Venta";
  } else {
    return formatDocument;
  }
}


export function formatDocumentType(documentType, documentOriginType, documentState) {
  let formatDocumentType = "";
  if (documentType) {
    switch (documentType) {
      case DocumentDomain.INVOICE:
        formatDocumentType = "Factura";
        break;
      case DocumentDomain.EXPENSE:
        if (documentOriginType === 'incomespot') {
          formatDocumentType = "Ingreso";
          break;

        } else {
          formatDocumentType = "Gasto";
          break;

        }
      case DocumentDomain.BILL:
        formatDocumentType = "Boleta";
        break;
      case DocumentDomain.MOVEMENT:
        formatDocumentType = "Movimiento";
        break;
      default:
        formatDocumentType = documentType;
    }
  }
  if (documentType && documentType === DocumentDomain.INVOICE && documentOriginType) {
    switch (documentOriginType) {
      case DocumentDomainSII.FACTURAFISICA:
        formatDocumentType = "Factura";
        break;
      case DocumentDomainSII.FACTURAELECTRONICA:
        formatDocumentType = "Factura";
        break;
      case DocumentDomainSII.NOTACREDITOFISICA:
        formatDocumentType = "Nota de Crédito";
        break;
      case DocumentDomainSII.NOTACREDITOELECTRONICA:
        formatDocumentType = "Nota de Crédito";
        break;
      case DocumentDomainSII.FACTURADECOMPRAELECTRONICA:
        formatDocumentType = "Factura de Compra";
        break;
      case DocumentDomainSII.NOTADEDEBITOFISICA:
        formatDocumentType = "Nota de Débito";
        break;
      case DocumentDomainSII.NOTADEBITOELECTRONICA:
        formatDocumentType = "Nota de Débito";
        break;
      case DocumentDomainSII.FACTURAELECTRONICAVENTA:
        formatDocumentType = "Factura Exenta";
        break;
      case DocumentDomainSII.FACTURAEXPORTACION:
        formatDocumentType = "Factura Exportación";
        break;

      default:
        formatDocumentType = documentType;
    }
    if (documentType && documentType === DocumentDomain.INVOICE && documentState) {
      switch (documentState) {
        case DocumentDomainSII.EMITIDO:
          formatDocumentType = formatDocumentType + " Emitida";
          break;
        case DocumentDomainSII.RECHAZADO:
          formatDocumentType = formatDocumentType + " Rechazada";
          break;
        case DocumentDomainSII.RECLAMADO:
          formatDocumentType = formatDocumentType + " Reclamada";
          break;
        case DocumentDomainSII.REGISTRO:
          formatDocumentType = formatDocumentType + " Registrada";
          break;

      }
    }
  }
  if (documentType && documentType === DocumentDomain.BILL && documentOriginType) {


    switch (documentOriginType) {
      case "siibillbh":
        formatDocumentType = formatDocumentType + " Honorarios";
        break;

    }
  }

  if (documentType && documentType === DocumentDomain.EXPENSE && documentOriginType) {


    switch (documentOriginType) {
      case "siitaxf22":
        formatDocumentType = formatDocumentType + " SII RENTA";
        break;
      case "siitaxf29":
        formatDocumentType = formatDocumentType + " SII IVA";
        break;
      case "wage":
        formatDocumentType = formatDocumentType + " Honorarios";
        break;
      case "salary":
        formatDocumentType = formatDocumentType + " Remuneración";
        break;
      case "incomespot":
        formatDocumentType = formatDocumentType + " Registrado";
        break;



    }
  }
  if (documentType && documentType === DocumentDomain.MOVEMENT && documentOriginType) {
    switch (documentOriginType) {
      case "other":
        formatDocumentType = "Otro Movimiento";
        break;
    }
  }
  // console.log("\nformatDocumentType:--> " + formatDocumentType);
  return formatDocumentType;
}

export function formatBank(bankid) {
  switch (bankid) {
    case "cl_banco_itau":
      return "Itaú";
    case "cl_banco_santander":
      return "Santander";
    case "cl_banco_bci":
      return "BCI";
    case "cl_banco_bice":
      return "BICE";
    case "cl_banco_bancoestado":
      return "Estado";
    case "cl_banco_bbva":
      return "BBVA";
    case "cl_banco_security":
      return "Security";
    case "cl_banco_de_chile":
      return "de Chile";
    case "cl_banco_falabella":
      return "Falabella";
    case "cl_banco_scotiabank":
      return "Scotiabank";
    case "cl_banco_corpbanc":
      return "Corpbanca";
    default:
      return bankid;
  }

}

export function shortDescription(description) {
  if (description) {
    return description.substring(0, 50) + '...';
  }
  return '';
}

export function veryShortDescription(description) {
  if (description) {
    if (description.length < 14) {
      return description;
    }
    return description.substring(0, 14) + '...';
  }
  return '';
}



export function getMonthsToDisplay(monthOne, monthTwo, monthThree) {
  const today = new Date();
  const monthOneName = moment(new Date(today.getFullYear(), (parseInt(monthOne) - 1), 1)).format('MMM');
  const monthTwoName = moment(new Date(today.getFullYear(), (parseInt(monthTwo) - 1), 1)).format('MMM');
  const monthThreeName = moment(new Date(today.getFullYear(), (parseInt(monthThree) - 1), 1)).format('MMM');

  return [monthOneName, monthTwoName, monthThreeName];
}

