import { v4 as uuid } from "uuid";

export class DocumentId {
  readonly value: string;
  constructor(value: string) {
    this.value = value;
  }

  toString(): string {
    return this.value;
  }

  static random(): DocumentId {
    return new DocumentId(uuid());
  }
}
