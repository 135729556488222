import moment from "moment";

export function formatDate(value) {
  if (value) {
    const myDate = new Date(value);
    const dd = myDate.getUTCDate();
    const mm = myDate.getUTCMonth();
    const yyyy = myDate.getUTCFullYear();

    const dateFormat = moment(new Date(yyyy, mm, dd)).format("DD/MM/YYYY");

    return dateFormat;
  }
}

export function getMonthYear(month, year) {
  const today = new Date();
  const monthName = moment(new Date(year, (parseInt(month) - 1), 1)).format('MMM');
  const monthYear = monthName + " " + year;

  return monthYear;
}

//TODO: ver lo del undefined de la descripcion
export function resumeText30Chars(value) {
  if (value) {
    if (value.length <= 30) {
      return value;
    }
    return value.substring(0, 30) + "...";
  }
}

export function formatDateFromISO(value) {
  if (value) {

    if (!isValidISODate(value)) {
      const myDate = new Date(value);
      const dd = myDate.getUTCDate();
      const mm = myDate.getUTCMonth();
      const yyyy = myDate.getUTCFullYear();

      return new Date(yyyy, mm, dd);
    } else {
      console.log("No pude identificar la fecha como válida: " + value)
      return value;
    }


  }
}

export function isValidISODate(str) {
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z$/;
  return isoDateRegex.test(str);
}
